import React, { useState } from "react";
import "../css/modal-obligaciones.css";
import { updateObligacion } from "../actions";
import { useDispatch } from "react-redux";

function ModalObligaciones({ idObligaciones, idNormaMatriz, showModal,toggleModal }) {
  // const [showModal, setShowModal] = useState(false);
  const [obligationOpen, setObligationOpen] = useState(true);
  const [trackingOpen, setTrackingOpen] = useState(false);
  
  const [obligacion, setObligacion] = useState("");
  const [verificaciones, setVerificaciones] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [archivo, setArchivo] = useState(false);

  const dispatch = useDispatch();

 
  const toggleObligation = () => setObligationOpen(!obligationOpen);
  const toggleTracking = () => setTrackingOpen(!trackingOpen);


  
  const handleSaveClick = () => {
    dispatch(updateObligacion(idNormaMatriz, idObligaciones, verificaciones, observaciones,obligacion));
    toggleModal();
  };

  return (
    <div>

      {showModal && (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "791px" }}
          >
            <div
              className="modal-content"
              style={{ width: "791px", height: "656px" }}
            >
              <div className="modal-header">
                <h5 className="modal-title obligations-title">Editar Obligación</h5>
                <button type="button" className="close" onClick={toggleModal}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/close-icon.svg"}
                    alt=""
                  />
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="acordion-header" id="headingOne">
                      <h2 className="mb-0 acordion-title">Obligación</h2>
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={toggleObligation}
                      >
                        <img
                          src={process.env.PUBLIC_URL + "/images/open-close-acordion.svg"}
                          className={obligationOpen ? "rotate" : ""}
                          alt=""
                        />
                      </button>
                    </div>
                    {obligationOpen && (
                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <form>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="obligacion"
                                className="col-sm-3 col-form-label text-label-modal"
                              >
                                Obligación
                              </label>
                              <div className="col-sm-9">
                                <textarea
                                  className="form-control textarea-acordion"
                                  id="obligacion"
                                  value={obligacion}
                                  onChange={(e) => setObligacion(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="verificacion"
                                className="col-sm-3 col-form-label text-label-modal"
                              >
                                Verificación
                              </label>
                              <div className="col-sm-9">
                                <textarea
                                  className="form-control textarea-acordion"
                                  id="verificacion"
                                  value={verificaciones}
                                  onChange={(e) => setVerificaciones(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="observaciones"
                                className="col-sm-3 col-form-label text-label-modal"
                              >
                                Observaciones
                              </label>
                              <div className="col-sm-9">
                                <textarea
                                  className="form-control textarea-acordion"
                                  id="observaciones"
                                  value={observaciones}
                                  onChange={(e) => setObservaciones(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                            {/* <div className="form-group row mb-3">
                              <label
                                htmlFor="archivo"
                                className="col-sm-3 col-form-label text-label-modal"
                              >
                                Archivo
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="checkbox"
                                  id="archivo"
                                  checked={archivo}
                                  onChange={(e) => setArchivo(e.target.checked)}
                                />
                              </div>
                            </div> */}
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-eleg" onClick={handleSaveClick}>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalObligaciones;