import React, { useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import TraductorDropdown from './TraductorDropdown';
import { globalJSONContext } from '../context/globalJsonDataContext';
import { getPaises } from '../actions';
import { useDispatch,useSelector } from 'react-redux';
import PaisesDropdown from './PaisesDropdown';
import { useNavigate } from 'react-router-dom';


function NavBar() {
  const {globalJSON} = useContext(globalJSONContext)
  const dispatch = useDispatch();
  const paises = useSelector(state=>state.paises_options)
  const navigate = useNavigate();



  // useEffect(() => {
  //   dispatch(getPaises());
  // }, []);


  const logout= ()=>{
    localStorage.clear();
  }

  return (

<nav className="navbar navbar-expand-lg navbar-light bg-white m-0">
    <div className="container">
        <a className="navbar-brand" href="/"><img src={process.env.PUBLIC_URL + '/images/logo-eleg-matriz-pro.svg'} alt='Logo'/></a>
      <PaisesDropdown/>
         <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
           <span className="navbar-toggler-icon"></span>
         </button>
         <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav  fw-lighter ml-auto">
            <li className="nav-item active">
              <a className="nav-link text-black" href="/matrices">{globalJSON.navbar?.arrays ?? "Undefined"}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-black" href="/empresas">{globalJSON.navbar?.companies}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-black" href="/plantas">{globalJSON.navbar?.floors}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-black" href="https://testclientes.miweb.io/logout.php" onClick={()=>{ logout()}}>SALIR</a>
            </li>
          </ul>
          <TraductorDropdown />
        </div>
  </div>
</nav>
  );
}

export default NavBar;
