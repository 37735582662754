import React, { useContext } from 'react'
import { paisSelected } from '../actions'
import { useDispatch } from 'react-redux'

const PaisesDropdown = () => {
    const dispatch = useDispatch()

//   const paisesDisp = [
//     {pais: 'argentina',idPais:1},
//     {pais: 'chile',idPais:3}
// ]

  const handleChangeCountry = (idCountry) => {
    dispatch(paisSelected(idCountry))
  }
  return (
    <div className="dropdown">
                <button className=" mi-boton dropdown-toggle " type="button" id="dropdownMenuButton1" style={{active: 'false', border:'none', background:'none'}} data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={process.env.PUBLIC_URL + `/images/argentina.png`} alt='ARG'/>
                </button>
                
       
        <ul className="dropdown-menu"  style={{minWidth: '20px'}} id="#traductorDropdown" aria-labelledby="dropdownMenuButton1" >
            <li><a className="dropdown-item" href="#" onClick={() => handleChangeCountry(1)}><img src={process.env.PUBLIC_URL + '/images/argentina.png'} alt='argentina'/></a></li>
            <li><a className="dropdown-item" href="#" onClick={() => handleChangeCountry(3)}><img src={process.env.PUBLIC_URL + '/images/chile.png'} alt='chile' style={{width:'20px'}}/></a></li>
        </ul>
    </div>
  )
}

export default PaisesDropdown