import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, json, Route, Routes,useNavigate} from 'react-router-dom';
import Inicio from './Pages/Inicio';
import Resumen from './Pages/Resumen';
import Wizard from './Pages/Wizard'
import {traductorContext} from './context/traductorContext';
import {globalJSONContext} from './context/globalJsonDataContext';
import USA from './Components/idiomas/english.json'
import ARG from './Components/idiomas/spanish.json'
import FRA from './Components/idiomas/french.json'
import BRA from './Components/idiomas/portuguese.json'
import {useDispatch, useSelector} from "react-redux";
import {UserManager} from "oidc-client";
import OidSettings from './Resources/OidSettings';
import {getUserStatus, setUserReducer} from '../src/actions/index'
import Empresas from "./Pages/Empresas";
import * as elegRepository from "./App";
import Plantas from "./Pages/Plantas";
import Normas from "./Pages/Normas";
import './App.css'


function App() {
   
    const dispatch = useDispatch();
    const {language} = useContext(traductorContext);
    const {setGlobalJSON} = useContext(globalJSONContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const urs = useSelector((state) => state.user);
    const userManager = new UserManager(OidSettings);
    const attemptMax = 3;

//   const handlerUser = (user) => {
    // dispatch(setUserReducer(user))
    // }

    const handlerLoading = () => {
        resetAttempts();
        setLoading(false)
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const resetAttempts = () => {
        let attemp = 0;
        localStorage.setItem('attempt', attemp.toString());
    }
    const incrementByOne = () => {
        if (!(localStorage.getItem('attempt') === null)) {
            let attemp = 1;
            localStorage.setItem('attempt', attemp.toString());
        } else {
            let attemp = parseInt(localStorage.getItem('attempt'));
            if (attemp > attemptMax) {
                resetAttempts();
                setError(true);
            }
            attemp++;
            localStorage.setItem('attempt', attemp.toString());
        }
    }
    const refresh = () => {
        incrementByOne();
        localStorage.setItem('login', "false");
        // window.location.reload();
    }


    useEffect(() => {
        const languageJSONMap = {
            ARG,
            USA,
            FRA,
            BRA
        };
        if (language in languageJSONMap) {
            setGlobalJSON(languageJSONMap[language]);
            localStorage.setItem('language', language);
        }
    }, [language]);

    let authLoading = false;
    useEffect(() => {
        
        if(authLoading) {
            return;
        }

        
        authLoading = true;

        let UserLoaded = false;
        if (!(localStorage.getItem('login') === "true")) {
            localStorage.setItem('login', "true");
            userManager.signinRedirect().catch((error) => console.log(error, "esto es errores"))
        } else {
            if ((window.location.search !== "")) {
                userManager.signinRedirectCallback(window.location)
                    .then(usr => {
                        
                        localStorage.setItem('user', JSON.stringify(usr));
                        
                        UserLoaded = true;
                        dispatch(setUserReducer(JSON.parse(localStorage.getItem('user'))))
                        handlerLoading();
                        
                    }).catch((error) => {
                        
                        console.log(error);
                        window.location.href = "/"

                    })
            }
            else {
                userManager.getUser().then((user) => {
                  if(!user){
                    userManager.signinRedirect().catch((error) => console.log(error, "esto es errores"))
                    return
                  }
                    localStorage.setItem('user', JSON.stringify(user));
                    
                    dispatch(setUserReducer(JSON.parse(localStorage.getItem('user'))))
                    UserLoaded = true;
                    handlerLoading();
                }).catch((error) => {
                    console.log(error, 'getUser');
                })
            }
        }
        if (!(localStorage.getItem('user') === null)) {
            
        } else {

            sleep(4000).then(() => {
                if (!UserLoaded) {
                    refresh();
                }
            })
        }

    }, [dispatch])


    useEffect(() => {
        
        if (urs && urs.profile?.email) {
          dispatch(getUserStatus(urs?.profile?.email))
            .then(response => {
              console.log('Response from getUserStatus:', response);
              if (response && response.payload && response.payload.Status === 'inactive') {
                window.location.replace('https://eleg.app/');
              }
            })
            .catch(error => {
              console.error('Error fetching user status:', error);
            });
        }
      }, [dispatch, urs.profile?.email]);

    if (loading) {
        return <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="spinner"></div>
                </div>; 
      }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Inicio/>}/>
                <Route path="/matrices" element={<Resumen/>}/>
                <Route path="/wizard" element={<Wizard/>}/>
                <Route path="/empresas" element={<Empresas/>}/>
                <Route path="/plantas" element={<Plantas/>}/>
                <Route path="/normas/:idmatriz" element={<Normas />}/>
            </Routes>
        </Router>
    );
}

export default App;






















