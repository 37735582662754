import React from 'react'

export default function ActiveFilters({ filters, visibility, onRemoveFilter }) {
    const activeFilters = Object.entries(filters).filter(([key, value]) => value && visibility[key]);
  
    return (
      <div style={{ display: 'flex',flexDirection:'row',marginBottom:'10px' }}>
        <h6 style={{paddingTop:"5px", marginLeft: "15px", fontWeight: '600',marginRight:'10px'}}>Filtros:</h6>
        <div className="active-filters" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {activeFilters.map(([key, value]) => (
          <button key={key} className="btn btn-filter-actives" onClick={() => onRemoveFilter(key)}>
            {key}: {value} <span className="remove-filter">X</span>
          </button>
        ))}
      </div>
      </div>
    );
}

