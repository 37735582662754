import React,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import {fetchDataNiveles,getDataNivel4,getDataNivel3, } from '../../actions/index.js';
import {RESET_DATA_LEVEL,} from "../../reducers/constReducer.js";
  

export default function InputsAlcance({handleChangeFormFilterAlcance, handleSaveSelectedValues, handleResetSelectedValues,}) {
    const idPais = useSelector((state)=>state.pais_selected)
    const user = useSelector((state) => state.user);
    const nivelesData = useSelector((state) => state.nivelesData);
    const nivel2Data = useSelector((state) => state.nivel2Data);
    const nivel3Data = useSelector((state) => state.nivel3Data);
    const nivel4Data = useSelector((state) => state.nivel4Data);
    const dispatch = useDispatch();
    const [selects, setSelect] = useState([]);
  
    useEffect(() => {
      dispatch(fetchDataNiveles(idPais, user.id_token));
    }, [dispatch, idPais, user.id_token]);
  
    useEffect(() => {
      if (nivelesData && nivelesData.Niveles !== 4) {
        setSelect([nivelesData.Nivel3, nivelesData.Nivel4]);
      } else {
        setSelect([nivelesData.Nivel2, nivelesData.Nivel3, nivelesData.Nivel4]);
      }
    }, [nivelesData]);
  
    const resetDatosSelects = () => {
      dispatch({
        type: RESET_DATA_LEVEL,
        payload: [],
      });
    };
  
  
    const handleChargeInfoSelects = (e, currentIndex) => {
      const idValueSelect = e.target.selectedOptions[0].getAttribute("data-id");
      const valueSelect = e.target.value;
  
     
  
      if (currentIndex === 0) {
        handleResetSelectedValues();
      }
  
      if (nivelesData.Niveles !== 4) {
        if (currentIndex === 0) {
          dispatch(getDataNivel4({ idValueSelect, idPais }, user.id_token));
        }
        if (currentIndex === 1) {
          handleChangeFormFilterAlcance(e, "idMunicipio", idValueSelect,valueSelect);
        }
      } else {
        if (currentIndex === 0) {
          resetDatosSelects();
          dispatch(getDataNivel3({ idValueSelect, idPais }, user.id_token));
        } else if (currentIndex === 1) {
          let nivel = "nivel4";
          dispatch(
            getDataNivel4({ idValueSelect, idPais, nivel }, user.id_token)
          );
        }
      }
      if (currentIndex === 2) {
        handleChangeFormFilterAlcance(e, "idProvincia", idValueSelect,valueSelect);
      }
  
      handleSaveSelectedValues(currentIndex, valueSelect, idValueSelect);
    };
  
    return (
      <>
        {selects?.map((e, i) => {
          let infoSelects = [];
  
          switch (e) {
            case nivelesData.Nivel2:
              infoSelects = nivel2Data;
              break;
            case nivelesData.Nivel3:
              infoSelects = nivel3Data;
              break;
            case nivelesData.Nivel4:
              infoSelects = nivel4Data;
              break;
            default:
              break;
          }
  
          return (
            <div className="d-flex selects-container" key={i}>
              <label htmlFor={`select${e}`} className="col-4 form-label me-2">
                <strong>{e}:</strong>
              </label>
              <select
                onChange={(e) => handleChargeInfoSelects(e, i)}
                name={`alcance${e}`}
                className="form-control select-filter-normas"
                id={`select${e}`}
              >
                <option value="">Seleccione {e}</option>
                {infoSelects?.map((option, j) => (
                  <option value={option.Nombre} data-id={option.Id} key={j}>
                    {option.Nombre}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </>
    );

}