import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getObligacionesByNorma,deleteObligacion } from "../../actions";
import ArrowIcon from "../../images/arrow-table-icon.svg";
import ModalObligaciones from "../ModalObligaciones.jsx";

const SubRowObligaciones = ({ normaId }) => {
  const dispatch = useDispatch();
  const [obligacionesPageSize, setObligacionesPageSize] = useState(3);
  const [obligacionesPageNumber, setObligacionesPageNumber] = useState(1);
  const [subRowData, setSubRowData] = useState({});
  const resetRequest = useSelector((state)=> state.resetRequestObligaciones)
  const [showModal, setShowModal] = useState(false);
  const [editObligationIds,setEditObligationIds] = useState({idNormaMatriz: '', idObligacion: ''})

  const toggleModal = (idNormaMatriz,idObligacion) => {

    setEditObligationIds({idNormaMatriz,idObligacion})
    
    setShowModal(!showModal);
  }
  

  const deleteObligationClick = (idNormaMatriz,idObligacion)=>{

    dispatch(deleteObligacion(idNormaMatriz,idObligacion))
  }
 
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getObligacionesByNorma(normaId, obligacionesPageNumber, obligacionesPageSize));
      setSubRowData(response);
    };
    fetchData();
  }, [normaId, obligacionesPageNumber, obligacionesPageSize, dispatch,resetRequest]);

  const handleSubNextPage = async (e) => {
    e.preventDefault();
    setObligacionesPageNumber(obligacionesPageNumber + 1);
  };

  const handleSubPrevPage = async (e) => {
    e.preventDefault();
    if (obligacionesPageNumber > 1) {
      setObligacionesPageNumber(obligacionesPageNumber - 1);
    }
  };

  const handleSubPageClick = async (pageNumber) => {
    setObligacionesPageNumber(pageNumber);
  };

  const renderSubPageNumbers = () => {
    const subTotalPages = subRowData.PageTotal || 1;
    const pageNumbers = [];
    for (let i = 1; i <= subTotalPages; i++) {
      pageNumbers.push(
        <a
          href="#"
          key={i}
          className={obligacionesPageNumber === i ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            handleSubPageClick(i);
          }}
        >
          {i}
        </a>
      );
    }
    return pageNumbers;
  };

  const subStartRecord = (obligacionesPageNumber - 1) * obligacionesPageSize + 1;
  const subEndRecord = subStartRecord + (obligacionesPageSize - 1);

  return (
    <div className="table-expanded">
     
      <div className="d-flex align-items-center">
        <img className="me-3" src={ArrowIcon} alt="Collapse" />
        <span> Obligaciones</span>
      </div>
      <table style={{ width: "93%", margin: "0 auto" }}>
        <thead>
          <tr>
            <th className="th-subtable">Obligación</th>
            <th className="th-subtable">Verificación</th>
            <th className="th-subtable">Observaciones</th>
            <th className="th-subtable">Edición</th>
          </tr>
        </thead>
        <tbody className="table-expanded-body">
          {subRowData.Data?.map((obligacion, index) => (
            <tr key={index} style={{ borderBottom: "1px solid #DADADA" }}>
              <td className="td-text-style">{obligacion.Obligacion}</td>
              <td className="td-text-style">{obligacion.Verificaciones}</td>
              <td className="td-text-style">{obligacion.Observaciones}</td>
              <td>
              <div class="dropdown">
                <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img
                    src={process.env.PUBLIC_URL + "/images/dotsObligations.svg"}
                    alt=""
                  />
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" onClick={()=>deleteObligationClick(obligacion.IDNormaMatriz,obligacion?.IDObligacion)}>Dar de baja</a></li>
                  <li><a class="dropdown-item" onClick={()=>toggleModal(obligacion.IDNormaMatriz,obligacion?.IDObligacion)}>Editar</a></li>
                </ul>
              </div>
              </td>
            </tr>
          )) || (
            <tr>
              <td colSpan="4">No hay registros disponibles</td>
            </tr>
          )}
        </tbody>
        <ModalObligaciones idObligaciones={editObligationIds?.idObligacion} showModal={showModal} toggleModal={toggleModal} idNormaMatriz={editObligationIds?.idNormaMatriz} />
      </table>
      <div className="subtable-pagination-container">
        <div className="registros-text-subtable">
          Mostrando registros del {subStartRecord} al {subEndRecord} de un total de {subRowData.PageTotal * obligacionesPageSize} registros
        </div>
        <div className="pagination">
          <a href="#" onClick={handleSubPrevPage} className={obligacionesPageNumber === 1 ? "disabled" : ""}>
            Anterior
          </a>
          <div className="numbers">{renderSubPageNumbers()}</div>
          <a href="#" onClick={handleSubNextPage} className={obligacionesPageNumber === subRowData.PageTotal ? "disabled" : ""}>
            Siguiente
          </a>
        </div>
      </div>
    </div>
  );
};

export default SubRowObligaciones;