import React, { useState, useMemo, useEffect } from "react";
import {
  getNormas,
  getNormasByGroup,
  getMatriz,
  getTemasMatrices,
  importNormas,
  getAlcances,
  getAutoridadDeAplicacion,
  getTipoMatrices,
} from "../../actions";
import { useTable, useExpanded } from "react-table";
import PlusIcon from "../../images/icon-plus-normas.svg";
import MinusIcon from "../../images/icon-less-normas.svg";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import CustomButtonWizard from "../modalWizard/CustomButtonWizard";
import RowNormas from "./RowNormas.jsx";
import ActiveFilters from "./ActiveFilters.jsx";
import InputsAlcance from "./InputsAlcance.jsx";



const ExpandableTable = ({ columns, idMatriz, importN }) => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(2);
  const [totalRecords, setTotalRecords] = useState(0);
  const [groupData, setGroupData] = useState([]);
  const [rowPageNumbers, setRowPageNumbers] = useState({});
  const [rowData, setRowData] = useState({});
  const [rowNormasPageSize, setRowNormasPageSize] = useState(10);
  const groupStartRecord = (pageNumber - 1) * pageSize + 1;
  const groupEndRecord = Math.min(pageNumber * pageSize, totalRecords);

  //////////////////////////////////////Estados Filtros//////////////////////////////////////////////////
  const [selectedValues, setSelectedValues] = useState({});
  const [temaDataId, setTemaDataId] = useState({ id: '', name: '' });
  const [norma, setNorma] = useState('');
  const [alcances,setAlcances] = useState([])
  const [alcanceId,setAlcanceId]= useState({ id: '', name: '' })
  const idPais = useSelector((state)=>state.pais_selected)
  const [idProvincia,setIdProvincia] = useState({ id: '', name: '' })
  const [idMunicipio,setIdMunicipio] = useState({ id: '', name: '' })
  const [autoridadAplicacion,setAutoridadAplicacion]=useState([])
  const [selectedValuesAutoridad, setSelectedValuesAutoridad] = useState({});
  const [idProvinciaAutoridad,setIdProvinciaAutoridad] = useState({ id: '', name: '' })
  const [idMunicipioAutoridad,setIdMunicipioAutoridad] = useState({ id: '', name: '' })
  const [idAutoridadAplicacion,setIdAutoridadAplicacion] = useState({ id: '', name: '' })
  const [resetRequest,setResetRequest]= useState(true)
  const [deBaja,setDeBaja] = useState({ id: '', name: '' })
  const [filterVisibility, setFilterVisibility] = useState({
    Provincia: false,
    Municipio: false,
    Tema: false,
    Norma: false,
    deBaja:false,
    ProvinciaAutAp:false,
    MunicipioAutAp:false,
    AutoridadAp:false,
    AlcanceAp:false,
  });
  const [activeTab, setActiveTab] = useState("Norma");
  const tabs = ["Norma", "Alcance", "Tema", "Autoridad de Aplicación"];
  const [listTemas, setListTemas] = useState([]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getNormasByGroup(idMatriz,
            pageNumber,
            pageSize,
            idPais,
            idProvincia.id,
            idMunicipio.id,
            temaDataId.id,
            norma,
            alcanceId.id,
            idProvinciaAutoridad.id,
            idMunicipioAutoridad.id,
            idAutoridadAplicacion.id,
            null,
            deBaja.id)
        );
        setTotalRecords(response.PageTotal * pageSize);
        setGroupData(response.Data);
        setExpandedRows({});
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [idMatriz, pageNumber, pageSize, dispatch, importN,resetRequest]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getMatriz(idMatriz));

        let idTipoMatriz = response?.data?.IDTipoMatriz;
        const resTemas = await dispatch(
          getTemasMatrices({ idPais, idTipoMatriz })
        );
        setListTemas(resTemas);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleNextPage = () => {
    if (pageNumber < Math.ceil(totalRecords / pageSize)) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    const pageNumbers = [];
    const maxPageNumbersToShow = 3;
    let startPage = Math.max(pageNumber - 1, 1);
    let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPageNumbersToShow) {
      startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <a
          href="#"
          key={i}
          className={pageNumber === i ? "active" : ""}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </a>
      );
    }

    return pageNumbers;
  };

 

  const handleRowClick = async (rowId, row) => {

    const currentPage = rowPageNumbers[rowId] || 1;
    const response = await dispatch(
      getNormas(
        idMatriz,
        currentPage,
        rowNormasPageSize,
        row.IDAlcance,
        row.IDPais,
        row.IDRegion,
        row.IDProvincia,
        row.IDMunicipio,
        row.IDTema,
        norma,
        idProvinciaAutoridad.id,
        idMunicipioAutoridad.id,
        idAutoridadAplicacion.id,
        deBaja.id
      )
    );

    setRowData((prevState) => ({
      ...prevState,
      [rowId]: response,
    }));
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  const transformedData = useMemo(
    () =>
      groupData?.map((norma, index) => ({
        id: index.toString(),
        alcance: norma.Alcance,
        provincia: norma.Provincia,
        municipio: norma.Municipio,
        tema: norma.Tema,
        ...norma,
      })),
    [groupData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: transformedData,
      },
      useExpanded
    );

  /////////////////filtros logica////////////////////////////


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAlcances(idPais));

        setAlcances(response)
        
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const idProvincia = selectedValues[0] ? selectedValues[0].dataId : '';
    const nameProvincia = selectedValues[0] ? selectedValues[0].value : '';
    const idMunicipio = selectedValues[1] ? selectedValues[1].dataId : '';
    const nameMunicipio = selectedValues[1] ? selectedValues[1].value : '';
    const idProvinciaAutoridad = selectedValuesAutoridad[0] ? selectedValuesAutoridad[0].dataId : '';
    const nameProvinciaAutoridad = selectedValuesAutoridad[0] ? selectedValuesAutoridad[0].value : '';
    const idMunicipioAutoridad = selectedValuesAutoridad[1] ? selectedValuesAutoridad[1].dataId : '';
    const nameMunicipioAutoridad = selectedValuesAutoridad[1] ? selectedValuesAutoridad[1].value : '';

    setIdProvincia({id:idProvincia,name:nameProvincia});
    setIdMunicipio({id:idMunicipio,name:nameMunicipio});
    setIdProvinciaAutoridad({id:idProvinciaAutoridad,name:nameProvinciaAutoridad});
    setIdMunicipioAutoridad({id:idMunicipioAutoridad,name:nameMunicipioAutoridad});
  }, [selectedValues,selectedValuesAutoridad]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAutoridadDeAplicacion(alcanceId.id,idPais,null,idProvinciaAutoridad.id,idMunicipioAutoridad.id));
        setAutoridadAplicacion(response)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [alcanceId,idPais,idProvinciaAutoridad,idMunicipioAutoridad]);



  const handleSelectChange = (e, setState) => {
    const selectedId = e.target.selectedOptions[0].getAttribute('data-id');
    const selectedName = e.target.selectedOptions[0].getAttribute('value');
    setState({ id: selectedId, name: selectedName });
  };



  const handleResetSelectedValues = () => {
    setSelectedValues({});
  };

  const handleSaveSelectedValues = (level, value, dataId) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [level]: { value, dataId },
    }));
  };

  const handleChangeFilterAlcance = (e, type, dataId,name) => {

    if (type === "idProvincia") {
      setIdProvincia({dataId,name});
    } else if (type === "idMunicipio") {
      setIdMunicipio({dataId,name});
    }
  };


  ////////////////////////////////////// AUTORIDAD APLICACIONN /////////////////////////////////////////

  const handleResetSelectedValuesAutoridad = () => {
    setSelectedValuesAutoridad({});
  };

  const handleSaveSelectedValuesAutoridad = (level, value, dataId) => {
    setSelectedValuesAutoridad((prevValues) => ({
      ...prevValues,
      [level]: { value, dataId },
    }));
  };

  const handleChangeFilterAlcanceAutoridad = (e, type, dataId,name) => {
    if (type === "idProvincia") {
      setIdProvinciaAutoridad({dataId,name});
    } else if (type === "idMunicipio") {
      setIdMunicipioAutoridad({dataId,name});
    }
  };



  /////////////////////////////////// filters active /////////////////////////////////
  
  const handleRemoveFilter = (filterKey) => {
    setResetRequest(!resetRequest)
    if (filterKey === "Provincia") {
      setIdProvincia({ id: '', name: '' });
    } else if (filterKey === "Municipio") {
      setIdMunicipio({ id: '', name: '' });
      setFilterVisibility((prevVisibility) => ({
        ...prevVisibility,
        Municipio: false,
      }))
    } else if (filterKey === "Tema") {
      setTemaDataId({ id: '', name: '' });
    } else if (filterKey === "Norma") {
      setNorma('');
    } else if (filterKey === "De Baja") {
      setDeBaja({ id: '', name: '' });
    } else if (filterKey == "AlcanceAp"){
      setAlcanceId({ id: '', name: '' })
    }else if (filterKey == "AutoridadAp"){
      setIdAutoridadAplicacion({ id: '', name: '' })
    }else if (filterKey == "ProvinciaAutAp"){
      setIdProvinciaAutoridad({ id: '', name: '' })
    }else if (filterKey == "MunicipioAutAp"){
      setIdMunicipioAutoridad({ id: '', name: '' })
    }

    setFilterVisibility((prevVisibility) => ({
      ...prevVisibility,
      [filterKey]: false,
    }));
  };

  

  const onClickFilter = (filterKey) => {

    if(filterKey === 'Autoridad'){
      setFilterVisibility((prevVisibility) => ({
        ...prevVisibility,
        ProvinciaAutAp:true,
        MunicipioAutAp:true,
        AutoridadAp:true,
        AlcanceAp:true,
      }))
    }
    if (filterKey == 'Norma'){
      setFilterVisibility((prevVisibility) => ({
        ...prevVisibility,
        Norma: true,
        'De Baja':true,
      }))
    }
    if (filterKey === 'Alcance') {

      idMunicipio != ''?
      setFilterVisibility((prevVisibility) => ({
        ...prevVisibility,
        Provincia: true,
        Municipio: true,
      })):setFilterVisibility((prevVisibility) => ({
        ...prevVisibility,
        Provincia: true,
      }))
    } else {
      setFilterVisibility((prevVisibility) => ({
        ...prevVisibility,
        [filterKey]: true,
      }));
    }
  }


    const filters = {
    Provincia: idProvincia.name,
    Municipio: idMunicipio.name,
    Tema: temaDataId.name,
    Norma: norma,
    'De Baja' : deBaja.name,
    ProvinciaAutAp:idProvinciaAutoridad.name,
    MunicipioAutAp:idMunicipioAutoridad.name,
    AutoridadAp:idAutoridadAplicacion.name,
    AlcanceAp:alcanceId.name,
  };
  


  

  const onClickAlcanceFilter = (filterKey) => {
    setPageNumber(1)
    setResetRequest(!resetRequest)
    onClickFilter(filterKey)
  };


  
  const renderTabContent = () => {
    switch (activeTab) {
      case "Norma":
        return (
          <div className="normas-filter">
            <div className="filter-bar">
              <div className="d-flex selects-container">
                <label
                  htmlFor={`#norma-search-input`}
                  className="col-4 form-label me-2"
                >
                  <strong>Norma:</strong>
                </label>
                <input
                  className="form-control select-filter-normas"
                  placeholder="Búsqueda por norma"
                  id="norma-search-input"
                  value={norma}
                  onChange={(e) => setNorma(e.target.value)}
                />
              </div>
                <div className="d-flex selects-container">
                  <label
                    htmlFor="si-no-select"
                    className="col-4 form-label me-2"
                  >
                    <strong>De Baja:</strong>
                  </label>
                  <select
                    onChange={(e) => handleSelectChange(e,setDeBaja)}
                    value={deBaja.name}
                    name="siNoSelect"
                    className="form-control select-filter-normas"
                    id="si-no-select"
                  >
                    <option value="">Seleccione opción</option>
                    <option value="Si" data-id="1">Sí</option>
                    <option value="No" data-id="0">No</option>
                  </select>
                </div>
              <CustomButtonWizard
                title="Filtrar"
                onClick={()=>onClickAlcanceFilter("Norma")}
              />
            </div>
          </div>
        );
      case "Alcance":
        return (
          <div className="alcance-filter">
            <div className="filter-bar">
              <InputsAlcance
                handleChangeFormFilterAlcance={handleChangeFilterAlcance}
                handleSaveSelectedValues={handleSaveSelectedValues}
                handleResetSelectedValues={handleResetSelectedValues}
              />
              <CustomButtonWizard
                title="Filtrar"
                onClick={()=>onClickAlcanceFilter("Alcance")}
              />
            </div>
          </div>
        );
      case "Tema":
        return (
          <div className="tema-filter">
            <div className="filter-bar">
              <div className="d-flex selects-container">
                <label
                  htmlFor={`temas-selectt`}
                  className="col-4 form-label me-2"
                >
                  <strong>Tema:</strong>
                </label>
                <select
                  onChange={(e)=>handleSelectChange(e,setTemaDataId)}
                  name={`temas`}
                  className="form-control select-filter-normas"
                  id={`temas-select`}
                >
                  <option value="">Seleccione tema</option>
                  {listTemas?.map((option, j) => {
                    return (
                      <option
                        value={option.Tema}
                        data-id={option.IDTema}
                        key={j}
                      >
                        {option.Tema}
                      </option>
                    );
                  })}
                </select>
              </div>
              <CustomButtonWizard title="Filtrar" onClick={()=>onClickAlcanceFilter("Tema")} />
            </div>
          </div>
        );
      case "Autoridad de Aplicación":
        return (
          <div className="autoridad-filter">
            <div className="filter-bar">
            <div className="d-flex selects-container">
                <label
                  htmlFor={`temas-selectt`}
                  className="col-4 form-label me-2"
                >
                  <strong>Alcance:</strong>
                </label>
                <select
                  onChange={(e)=>handleSelectChange(e,setAlcanceId)}
                  name={`alcances`}
                  className="form-control select-filter-normas"
                  id={`alcance-select`}
                >
                  <option value="">Seleccione alcance</option>
                  {alcances?.map((option, j) => {
                    return (
                      <option
                        value={option.Alcance}
                        data-id={option.IDAlcance}
                        key={j}
                      >
                        {option.Alcance}
                      </option>
                    );
                  })}
                </select>
              </div>
              <InputsAlcance
                handleChangeFormFilterAlcance={handleChangeFilterAlcanceAutoridad}
                handleSaveSelectedValues={handleSaveSelectedValuesAutoridad}
                handleResetSelectedValues={handleResetSelectedValuesAutoridad}
              />

              <div className="d-flex selects-container">
                <label
                  htmlFor={`temas-selectt`}
                  className="col-4 form-label me-2"
                >
                  <strong>Aut.Apl:</strong>
                </label>
                <select
                  onChange={(e)=>handleSelectChange(e,setIdAutoridadAplicacion)}
                  name={`alcances`}
                  className="form-control select-filter-normas"
                  id={`alcance-select`}
                >
                  <option value="">Seleccione Autoridad</option>
                  {autoridadAplicacion?.map((option, j) => {
                    return (
                      <option
                        value={option.Nombre}
                        data-id={option.Id}
                        key={j}
                      >
                        {option.Nombre}
                      </option>
                    );
                  })}
                </select>
              </div>
              <CustomButtonWizard title="Filtrar" onClick={()=>onClickAlcanceFilter("Autoridad")}/>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  //////////////////////////////////////////////////////////
  return (
    <>
      <div className="w-100">
      
        <div className="navbarnormas">
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`tab ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </div>
          ))}
          <div
            className="underline"
            s
            style={{ left: `${tabs.indexOf(activeTab) * 25}%` }}
          />
        </div>
        <div>{
        renderTabContent()}
        <ActiveFilters filters={filters} visibility={filterVisibility} onRemoveFilter={handleRemoveFilter} />
        </div>
      </div>
      <table
        {...getTableProps()}
        style={{ width: "100%", backgroundColor: "#fff" }}
      >
        <thead>
          {headerGroups.map((headerGroup,i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              <th className="th-style"></th>
              {headerGroup.headers.map((column,i) => (
                <th {...column.getHeaderProps()} key={i} className="th-style">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="tbody-style">
          {rows.map((row,i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr
                  {...row.getRowProps()}
                  style={{
                    borderBottom: "1px solid #dadada",
                    borderTop: "1px solid rgb(218, 218, 218)",
                  }}
                >
                  <td
                    onClick={() => handleRowClick(row.id, row.original)}
                    style={{ cursor: "pointer" }}
                  >
                    {expandedRows[row.id] ? (
                      <img src={MinusIcon} alt="Collapse" />
                    ) : (
                      <img src={PlusIcon} alt="Expand" />
                    )}
                  </td>
                  {row.cells.map((cell,i) => (
                    <td
                      {...cell.getCellProps()}
                      style={{ textAlign: "center" }}
                      key={i}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
                {expandedRows[row.id] && (
                  <tr>
                    <td colSpan={7}>
                      <RowNormas
                        rowId={row.id}
                        idMatriz={idMatriz}
                        rowOriginal={row.original}
                        norma={norma}
                        idProvinciaAutoridad={idProvinciaAutoridad.id}
                        idMunicipioAutoridad={idMunicipioAutoridad.id}
                        idAutoridadAplicacion={idAutoridadAplicacion.id}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="registros-text">
        Mostrando registros del {groupStartRecord} al {groupEndRecord} de un
        total de {totalRecords} registros
      </div>
      <div className="pagination">
        <a
          href="#"
          onClick={handlePrevPage}
          className={pageNumber === 1 ? "disabled" : ""}
        >
          Anterior
        </a>
        <div className="numbers">{renderPageNumbers()}</div>
        <a
          href="#"
          onClick={handleNextPage}
          className={
            pageNumber === Math.ceil(totalRecords / pageSize) ? "disabled" : ""
          }
        >
          Siguiente
        </a>
      </div>
    </>
  );
};

export default ExpandableTable;
