import React, { useContext } from "react";
import Navbar from "../Components/Navbar";
import { globalJSONContext } from "../context/globalJsonDataContext";
import ExpandableTable from "../Components/ExpandableTable/ExpandableTable";
import "../css/normas.css";
import CustomButtonWizard from "../Components/modalWizard/CustomButtonWizard";
import SVG from "../images/icon-plus-normas.svg";
import NormasNavbar from "../Components/NormasNavbar/NormasNavbar";
import plusIcon from "../images/icon-plus.svg";
import { useParams } from "react-router";
import { getNormas, importNormas } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import ModalObligaciones from "../Components/ModalObligaciones";

function Normas() {
  const { idmatriz } = useParams();

  const dispatch = useDispatch();
  const normas = useSelector((state) => state.normas_table);
  const [importN,setImportN]= useState(false)

  


  const { globalJSON } = useContext(globalJSONContext);

  const columns = [
    {
      Header: "Alcance",
      accessor: "alcance",
    },
    {
      Header: "Provincia",
      accessor: "provincia",
    },
    {
      Header: "Municipio",
      accessor: "municipio",
    },
    {
      Header: "Tema",
      accessor: "tema",
    },
  ];




  const onClickImport =() => {

    const fetchData = async () => {
      
      try {
        const response = await dispatch(importNormas(idmatriz));

        if(response == "OK"){
          setImportN(!importN)
        }
       
       
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
   
  
  };

  return (
    <div>
      <Navbar />

      <div className="app-lead-light  wizard position-relative ">
        <div className="container justify-content-between mx-auto d-flex">
          <div>
            <h4 className="fw-bold">Matrices</h4>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item fs-6 fw-lighter">
                  <a href="/">Inicio</a>
                </li>
                <li
                  className="breadcrumb-item active fs-6 fw-lighter"
                  aria-current="page"
                >
                  Matrices
                </li>
              </ol>
            </nav>
          </div>

          <div className="d-flex align-items-center gap-3">
            <button
              type="button"
              className="btn btn-eleg d-flex align-items-center gap-2 text-white fw-medium"
              onClick={()=>{ onClickImport()}}
            >
              Importar Normas
            </button>
          </div>
        </div>
      </div>
      <div className="app-card mx-auto mb-4 mt-4 py-0 px-4 px-xl-0" style={{maxWidth: '1325px'}}>
        <div className="app-card-body bg-light align-items-start d-flex flex-column " style={{paddingBottom: '0px', margin: '0px'}}>
          <div className="container-lg px-0 ">
            <ExpandableTable columns={columns}  idMatriz={idmatriz} importN={importN}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Normas;
