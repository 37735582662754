import React, { useState, useEffect } from "react";
import { useDispatch ,useSelector} from "react-redux";
import { getNormas,deleteNorma } from "../../actions";
import PlusIcon from "../../images/icon-plus-normas.svg";
import MinusIcon from "../../images/icon-less-normas.svg";
import SubRowObligaciones from "./SubRowObligaciones";
import ArrowIcon from "../../images/arrow-table-icon.svg";
import "./styles.css"

const RowNormas = ({ rowId, idMatriz, rowOriginal,norma, idProvinciaAutoridad, idMunicipioAutoridad,idAutoridadAplicacion }) => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState({});
  const [rowNormasPageSize, setRowNormasPageSize] = useState(10);
  const [rowNormasPageNumber, setRowNormasPageNumber] = useState(1);
  const [rowData, setRowData] = useState({});
  const resetRequest = useSelector((state)=> state.resetRequestObligaciones)


  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getNormas(
          idMatriz,
          rowNormasPageNumber,
          rowNormasPageSize,
          rowOriginal.IDAlcance,
          rowOriginal.IDPais,
          rowOriginal.IDRegion,
          rowOriginal.IDProvincia,
          rowOriginal.IDMunicipio,
          rowOriginal.IDTema,
          norma,
          idProvinciaAutoridad,
          idMunicipioAutoridad,
          idAutoridadAplicacion
        )
      );
      setRowData(response);
    };
    fetchData();
  }, [rowId, idMatriz, rowNormasPageNumber, rowNormasPageSize, dispatch, rowOriginal,resetRequest]);


  const deleteNormaClick =(idNormaMatriz)=>{
    dispatch(deleteNorma(idNormaMatriz))
  }

  const handleSubRowClick = async (normaId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [normaId]: !prevState[normaId],
    }));
  };

  const handleRowNormasNextPage = async () => {
    setRowNormasPageNumber(rowNormasPageNumber + 1);
  };

  const handleRowNormasPrevPage = async () => {
    if (rowNormasPageNumber > 1) {
      setRowNormasPageNumber(rowNormasPageNumber - 1);
    }
  };

  const handleRowNormasPageClick = async (pageNumber) => {
    setRowNormasPageNumber(pageNumber);
  };

  const startRecord = (rowNormasPageNumber - 1) * rowNormasPageSize + 1;
  const endRecord = Math.min(startRecord + rowNormasPageSize - 1, rowData.PageTotal * rowNormasPageSize || 0);

  return (
    <div className="table-expanded">
      <table className="sub-table" style={{ width: "100%" }}>
      <thead>
          <tr>
            <th style={{ borderRight: "none" }}>
              <img src={ArrowIcon} alt="Collapse" />
            </th>
            <th style={{ borderLeft: "none", textAlign: "left" }}> Norma</th>
            <th>Org. emisor</th>
            <th>A/R</th>
            <th>Aut.Aplicacion</th>
            <div className="combined-th">
              <th>Descripción</th>
              <th>Observaciones</th>
            </div>
          </tr>
        </thead>
        <tbody className="sub-table-body">
          {rowData.Data?.map((norma) => (
            <React.Fragment key={norma.IDNormaMatriz}>
              <tr
                
                className="tr-divider-bottom"
                style={{ cursor: "pointer", verticalAlign: "sub" }}
              >
                <td onClick={() => handleSubRowClick(norma.IDNormaMatriz)}>
                  {norma.ObligacionesCant > 0 ? (
                    expandedRows[norma.IDNormaMatriz] ? (
                      <img src={MinusIcon} alt="Collapse" />
                    ) : (
                      <img src={PlusIcon} alt="Expand" />
                    )
                  ) : (
                    <></>
                  )}
                </td>
                <td className="p-2 td-text-style">{norma.Norma}</td>
                <td className="p-2 td-text-style">{norma.Organismo}</td>
                <td className="p-3 td-text-style">{norma.AR}</td>
                <td className="p-2 td-text-style">{norma.IDAutoridadAplicacion}</td>
                <div className="combined-th">
                <td className="p-2 td-text-style">{norma.Descripcion}</td>
                <td className="p-2 td-text-style">{norma.Observaciones}</td>
                </div>
                <td>
                <div class="dropdown">
                <button class="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img
                    src={process.env.PUBLIC_URL + "/images/dotsObligations.svg"}
                    alt=""
                  />
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" onClick={()=>deleteNormaClick(norma.IDNormaMatriz)}>Dar de baja norma</a></li>
                </ul>
              </div>
                </td>
              </tr>
              {expandedRows[norma.IDNormaMatriz] && (
                <tr>
                  <td colSpan="7" style={{width: "100%"}}>
                    <SubRowObligaciones normaId={norma.IDNormaMatriz} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          )) || (
            <tr>
              <td colSpan="7">No hay registros disponibles</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="subtable-pagination-container" style={{width: "98%"}}>
        <div className="registros-text-subtable">
          Mostrando registros del {startRecord} al {endRecord} de un total de {rowData.PageTotal * rowNormasPageSize} registros
        </div>
        <div className="pagination">
          <a href="#" onClick={handleRowNormasPrevPage} className={rowNormasPageNumber === 1 ? "disabled" : ""}>
            Anterior
          </a>
          <div className="numbers">
            {Array.from({ length: rowData.PageTotal }, (_, i) => (
              <a
                href="#"
                key={i + 1}
                className={rowNormasPageNumber === i + 1 ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  handleRowNormasPageClick(i + 1);
                }}
              >
                {i + 1}
              </a>
            ))}
          </div>
          <a href="#" onClick={handleRowNormasNextPage} className={rowNormasPageNumber === rowData.PageTotal ? "disabled" : ""}>
            Siguiente
          </a>
        </div>
      </div>
    </div>
  );
};

export default RowNormas;