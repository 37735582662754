import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import '../main.scss';
import MenuWizardStep from '../Components/modalWizard/MenuWizard';
import ModalContent from '../Components/modalWizard/ModalContent'
import TimeLandWizard from '../Components/modalWizard/TimeLandWizard';
import { useDispatch, useSelector } from "react-redux";
import { getEmpresasUser, getPlantaByEmpresaID,getAlcancesByEmpresaID,getMatrices ,getTemasMatrices} from '../actions/index';
import { stepWizard } from '../Components/modalWizard/js/stepsWizard';
import {CLOSE_MODAL , OPEN_MODAL }  from '../reducers/constReducer.js'

const Inicio = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const empresasUser = useSelector((state) => state.empresasUser);
  const plantasEmpresa = useSelector((state) => state.plantasEmpresa);
  // const alcancesIDplanta = useSelector((state) => state.alcancesIDplanta);
  const matricesIdplanta = useSelector((state) => state.matricesIdplanta);
  // const temasIdplanta = useSelector((state) => state.temasIdplanta);
  const wizarStep = useSelector((state) => state.wizarStep);
  const openModal2 = useSelector((state) => state.openModal);
   const userStatus = useSelector((state) => state.user_status)
  

  const [idEmpresa , setIdEmpresa] = useState('')
  const [iDPlanta , setIDPlanta] = useState('')

  const {EMPRESA, PLANTA, ALCANCE, MATRIZ, TEMAS , MATRIZ_CREADA} = stepWizard
 
 

  const openModal = () => {
    dispatch({
      type:OPEN_MODAL,
    })
  };

  const closeModal = () => {
    dispatch({
      type: CLOSE_MODAL,
    })
  };

  

  useEffect(()=> {
    switch (wizarStep) {
      case EMPRESA:
    // llamar al back para traerme la data de Empresas segun el usuario
         dispatch(getEmpresasUser(user.id_token))
        break;
      case PLANTA:
    // llamar al back para traerme las plantas que pertenecen a esa Empresa
         // funcion para llamar al back
        dispatch(getPlantaByEmpresaID(user.id_token, empresasUser[0].IDEmpresa ))
        setIdEmpresa(empresasUser[0].IDEmpresa)
        break;
      case ALCANCE:
        dispatch(getAlcancesByEmpresaID(plantasEmpresa[0].IDPlanta,userStatus?.Countries[0]?.IDUsuarioPais))
        setIDPlanta(plantasEmpresa[0].IDPlanta)
        // llamar al back para traerme los alcances que tiene comprados. 
        break;
      case MATRIZ:
        // dispatch(getTipoMatrices(user.id_token))
        dispatch(getMatrices(plantasEmpresa[0].IDPlanta ,user.id_token ))
    // llamar al back para traerme las matricez que tiene y disponibles para esa planta segun el alcance
        break
      case TEMAS:

      
    // llamar al back para traerme los temas que tiene esa matriz 

        break;
      case MATRIZ_CREADA:
    // llamar al back para traerme los temas que tiene esa matriz 
        //  dispatch(getMatricesByIDMatriz()) ---> en esto te quedaste 02/20/2024
        break;
    
      default:
        break;
    }


  },[wizarStep])




  return (
    <div>
      <Navbar />
      <div className="app-lead-light  wizard position-relative">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="title">{wizarStep == MATRIZ_CREADA ? "Configuración Exitosa" :  "Configurar Matriz" }</h1>
            </div>
          </div>
        </div>
        <TimeLandWizard/>
      </div>
      <div className="app-card mx-auto mt-5 mb-4 py-0 px-4 px-xl-0">
        <div className="app-card-body bg-light align-items-center d-flex m-0">
          <div className="container-lg py-4 py-lg-0">
            <div className="row pb-4 pb-md-0">
              <div className="col-12 p-4 p-sm-5 text-center fw-bold">
                {!openModal2 ?
                <>
                {/* {wizarMenu} */}
                <MenuWizardStep openModal={openModal} iDPlanta={iDPlanta}   /> 
                </>: 
                <>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center p-4">
          <div className="col-auto fw-bold">
            <a href="#" className="text-link-eleg">
              Ayuda
            </a>
          </div>
        </footer>
      </div>
      <ModalContent modalIsOpen={openModal2} closeModal={closeModal} idEmpresa={idEmpresa} iDPlanta={iDPlanta} />
    </div>
    
  );
};

export default Inicio;