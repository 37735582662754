// Plantas.js
import React, {useEffect, useState} from 'react';
import Navbar from "../Components/Navbar";
import searchIcon from "../images/icon-search.svg";
import plusIcon from "../images/icon-plus.svg";
import editIcon from "../images/icon-edit.svg";
import pinIcon from "../images/icon-map-pin.svg";
import trashIcon from "../images/icon-trash.svg";
import Modal from "react-modal";
import '../main.scss';
import {useDispatch, useSelector} from "react-redux";
import {deletePlantaById, editPlantaById, getPlantasUser} from "../actions";
import {useNavigate} from "react-router-dom";
import {Button, Input} from "antd";
import {SET_PLANTAS} from "../reducers/constReducer";

function Plantas() {

  const [openEditModal, setOpenEditModal] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const [filteredText, setFilteredText] = useState('');
  const [handleEditPlanta, setHandleEditPlanta] = useState('');
  const [handleEditDireccion, setHandleEditDireccion] = useState('')
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const plantasUser = useSelector((state) => state.plantasUser);
  const navigate = useNavigate();

  const handleOpenEditModal = (planta) => {
    setOpenEditModal(planta);
    setHandleEditPlanta(planta.Planta);
    setHandleEditDireccion(planta.Direccion)
  }


  const handleOpenDeleteModal = (planta) => {
    setOpenDeleteModal(planta);
  }

  const handleChange = (event) => {
    setFilteredText(event.target.value);
  }

  const handleDelete = async (idPlanta) => {
    const response = await deletePlantaById(idPlanta);
    if (response.status === 204) { // no content
      dispatch({
        type: SET_PLANTAS,
        payload: plantasUser.filter((planta) => planta.IDPlanta !== idPlanta)
      });
      setOpenDeleteModal(null);
    }
  };

  const handleEdit = async (idPlanta) => {
    const updatedData = {
      Planta: handleEditPlanta,
      Direccion: handleEditDireccion
    };

    const response = await editPlantaById(idPlanta, updatedData);
    if (response.status === 200) {
      dispatch({
        type: SET_PLANTAS,
        payload: plantasUser.map((planta) => planta.IDPlanta === idPlanta ? {
          ...planta, ...updatedData
        } : planta)
      });
      setOpenEditModal(null);
      setHandleEditPlanta('');
      setHandleEditDireccion('');
    }
  }

  useEffect(() => {
    dispatch(getPlantasUser(user.id_token));
  }, []);

  return (
      <div>
        <Navbar/>
        <div className="app-lead-light wizard position-relative">
          <div className="container justify-content-between mx-auto d-flex">
            <div>
              <h4 className='fw-bold'>Plantas</h4>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item fs-6 fw-lighter"><a href="/">Inicio</a></li>
                  <li className="breadcrumb-item active fs-6 fw-lighter" aria-current="page">Plantas</li>
                </ol>
              </nav>
            </div>

            <div className="d-flex align-items-center gap-3">
              <div className="input-group w-25 flex-grow-1">
                <input type="text" className="form-control border-opacity-25 border-dark"
                       placeholder="Buscar planta"
                       aria-label="Buscar planta"
                       aria-describedby="button-addon2"
                       onChange={handleChange}
                       value={filteredText}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="app-card mx-auto mb-4 py-0 px-4 px-xl-0">
          <div className="app-card-body bg-light align-items-start d-flex">
            <div className="container-lg p-4">
              <table className="table table-borderless custom-table">
                <thead>
                <tr>
                  <th scope="col" className="col-8">Plantas</th>
                  <th scope="col" className='text-center'>Editar</th>
                  <th scope="col" className='text-center'>Eliminar</th>
                </tr>
                </thead>
                <tbody>
                {plantasUser.filter((planta) => {
                  return planta.Planta.toLowerCase().includes(filteredText.toLowerCase());
                }).map(planta => {
                  return (
                      <tr key={planta.IDPlanta}>
                        <td>{planta.Planta}</td>
                        <td className='text-center'>
                          <img src={editIcon} onClick={() => handleOpenEditModal(planta)}/>
                        </td>
                        <td className='text-center'>
                          <img src={trashIcon} onClick={() => handleOpenDeleteModal(planta)}/>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {openEditModal && (
            <div className="container">
              <Modal
                  className="custom-Modal"
                  isOpen={true}
                  onRequestClose={() => {
                    setOpenEditModal(null);
                    setHandleEditPlanta('');
                    setHandleEditDireccion('');
                  }}
                  contentLabel="edit"
              >
                <div className={"container custom-content-modal col-8 d-flex flex-column"}>
                  <div className="pt-3 modal-header fs-5">
                    <span>{'Editar planta'}</span>
                    <button className="btn-close-modal" type="button" onClick={() => {
                      setOpenEditModal(null);
                      setHandleEditPlanta('');
                      setHandleEditDireccion('');
                    }}>X</button>
                  </div>
                  <div className='d-flex flex-column gap-3 p-5'>
                    <Input placeholder={openEditModal.Planta}
                           value={handleEditPlanta}
                           onChange={(e) => setHandleEditPlanta(e.target.value)}
                    />
                    <Input placeholder={openEditModal.Direccion}
                           value={handleEditDireccion}
                           onChange={(e) => setHandleEditDireccion(e.target.value)}
                    />
                    <div className='d-flex gap-3 justify-content-center'>
                      <Button onClick={() => {
                        setOpenEditModal(null);
                        setHandleEditPlanta('');
                        setHandleEditDireccion('');
                      }}>Cancelar</Button>
                      <Button className={`bg-primary text-white ${!handleEditPlanta || !handleEditDireccion ? 'bg-black opacity-25' : ''}`}
                              disabled={!handleEditPlanta || !handleEditDireccion}
                              onClick={() => handleEdit(openEditModal.IDPlanta)}>Editar</Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
        )}

        {openDeleteModal && <div className="container">
          <Modal
              className="custom-Modal"
              isOpen={true}
              onRequestClose={() => setOpenDeleteModal(null)}
              contentLabel="delete"
          >
            <div className={"container custom-content-modal col-8 d-flex flex-column"}>
              <div className="pt-3 modal-header fs-5">
                <span>{'Eliminar Planta'}</span>
                <button className="btn-close-modal" type="button" onClick={() => setOpenDeleteModal(null)}>
                  X
                </button>
              </div>
              <h5 className='text-center mt-5'>¿Estás seguro que deseas eliminar la planta <b>{openDeleteModal.Planta}</b>?</h5>
              <div className='d-flex flex-row p-4 gap-3 justify-content-center'>
                <Button onClick={() => setOpenDeleteModal(null)}>Cancelar</Button>
                <Button className='bg-danger text-white' onClick={() => handleDelete(openDeleteModal.IDPlanta)}>Eliminar</Button>
              </div>
            </div>
          </Modal>
        </div>}
      </div>
  );
}

export default Plantas;
