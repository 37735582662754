import React from 'react'
import { headerStepsWizard } from './js/stepsWizard'
import { useSelector } from 'react-redux';
import checkSvg  from '../../images/check2-circle-01.svg'

const TimeLandWizard = () => {
  const wizarStep = useSelector((state) => state.wizarStep);

  return (
    <div className="timeline">
          <div className="container">
            <div className="row">
           
                {headerStepsWizard.map((title, index) => {
                  return(
                    <div key={index} className=" col-12 col-sm">
                       
                    <div className={` ${wizarStep === index ? 'active' : ''} steep-bubble`}>
                      <div className="static-tooltip">
                        <span>{title}</span>
                        <div className={` ${index < wizarStep ? 'custom-stiles' : 'bg-white'} bubble`}>
                          {index < wizarStep &&
                            <img className=''  src={checkSvg} alt='checked'/>
                          }
                        </div>
                      </div>
                    </div>
                 </div>
                  )
                })}
            </div>
          </div>
        </div>
  )
}

export default TimeLandWizard
