// Empresas.js
import React, {useEffect, useState} from 'react';
import Navbar from "../Components/Navbar";
import searchIcon from "../images/icon-search.svg"
import plusIcon from "../images/icon-plus.svg"
import editIcon from "../images/icon-edit.svg"
import pinIcon from "../images/icon-map-pin.svg"
import trashIcon from "../images/icon-trash.svg"
import Modal from "react-modal";
import '../main.scss';
import {useDispatch, useSelector} from "react-redux";
import {deleteEmpresaById, editEmpresaById, getEmpresasUser} from "../actions";
import {useNavigate} from "react-router-dom";
import {Button, Input} from "antd";
import {SET_EMPRESAS} from "../reducers/constReducer";

function Empresas() {

    const [openEditModal, setOpenEditModal] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(null)
    const [filteredText, setFilteredText] = useState('')
    const [handleEditEmpresa, setHandleEditEmpresa] = useState('')
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const empresasUser = useSelector((state) => state.empresasUser);
    const navigate = useNavigate();

    const handleOpenEditModal = (empresa) => {
        setOpenEditModal(empresa)

    }

    const handleOpenDeleteModal = (empresa) => {
        setOpenDeleteModal(empresa)

    }

    const handleChange = (event) => {
        setFilteredText(event.target.value);
    }

    const handleDelete = async (idEmpresa) => {
        const response = await deleteEmpresaById(idEmpresa)
        if (
            response.status === 204 //no content
        ){
            dispatch({
                type: SET_EMPRESAS,
                payload: empresasUser.filter((empresa)=> empresa.IDEmpresa !== idEmpresa )
            })
            setOpenDeleteModal(null)
        }
    };

    const handleEdit = async(idEmpresa) => {
        const response = await editEmpresaById(idEmpresa, handleEditEmpresa)
        if (
            response.status === 200
        ){
            dispatch({
                type:SET_EMPRESAS,
                payload: empresasUser.map((empresa) => empresa.IDEmpresa === idEmpresa ? {
                    ...empresa, Empresa: handleEditEmpresa
                }: empresa)
            })
            setOpenEditModal(null)
            setHandleEditEmpresa('')
        }
    }

    useEffect(() => {
        dispatch(getEmpresasUser(user.id_token))
    }, []);


    return (

        <div>
            <Navbar/>
            <div className="app-lead-light  wizard position-relative ">
                <div className="container justify-content-between mx-auto d-flex">
                    <div>
                        <h4 className='fw-bold'>Empresas</h4>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item fs-6 fw-lighter"><a href="/">Inicio</a></li>
                                <li className="breadcrumb-item active fs-6 fw-lighter" aria-current="page">Empresas</li>
                            </ol>
                        </nav>
                    </div>

                    <div className="d-flex align-items-center gap-3">
                        <div className="input-group w-25 flex-grow-1">
                            <input type="text" className="form-control border-opacity-25 border-dark"
                                   placeholder="Buscar empresa"
                                   aria-label="Buscar empresa"
                                   aria-describedby="button-addon2"
                                   onChange={handleChange}
                                   value={filteredText}
                            />
                        </div>
                        <button type="button"
                                className="btn btn-primary d-flex align-items-center gap-2 text-white fw-medium rounded"

                        >
                            <img src={plusIcon}/> Crear Empresa
                        </button>
                    </div>
                </div>

            </div>
            <div className="app-card mx-auto mb-4 py-0 px-4 px-xl-0">
                <div className="app-card-body bg-light align-items-start d-flex ">
                    <div className="container-lg  p-4">
                        <table className="table table-borderless custom-table">
                            <thead>
                            <tr>
                                <th scope="col" className="col-8">Empresas</th>
                                <th scope="col" className='text-center'>Editar</th>
                                <th scope="col" className='text-center'>Plantas</th>
                                <th scope="col" className='text-center'>Eliminar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {empresasUser.filter((empresa) => {
                                return empresa.Empresa.toLowerCase().includes(filteredText.toLowerCase());
                            } ).map(empresa => {
                                return (

                                <tr>
                                <td>{empresa.Empresa}</td>
                            <td className='text-center'><img src={editIcon}
                                                             onClick={() => handleOpenEditModal(empresa)}/>
                            </td>
                            <td className='text-center'><img src={pinIcon}/></td>
                            <td className='text-center'><img src={trashIcon}
                                                             onClick={() => handleOpenDeleteModal(empresa)}/>
                            </td>
                        </tr>)

                        }) }


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {openEditModal && <div className="container"><Modal
                className="custom-Modal"
                isOpen={true}
                onRequestClose={() => {
                    setOpenEditModal(null)
                    setHandleEditEmpresa('')
                }
            }
                contentLabel="edit"
            >
                <div

                    className={"container custom-content-modal col-8 d-flex flex-column"}>
                    <div className=" pt-3 modal-header fs-5">
                        <span>{'Editar empresa'}</span>
                        <button className="btn-close-modal" type="button" onClick={() => {
                            setOpenEditModal(null)
                            setHandleEditEmpresa('')
                        }}>
                            X
                        </button>
                    </div>
                    <div className='d-flex flex-column gap-3 p-5'>
                        <Input placeholder={openEditModal.Empresa}
                               value = {handleEditEmpresa}
                               onChange = {(e) => setHandleEditEmpresa(e.target.value) }
                        ></Input>
                        <div className='d-flex gap-3 justify-content-center'>
                            <Button onClick={() => {
                                setOpenEditModal(null)
                                setHandleEditEmpresa('')
                            }}>Cancelar</Button>
                            <Button className={`bg-primary text-white ${!handleEditEmpresa ? 'bg-black opacity-25':''}`} disabled={!handleEditEmpresa} onClick={() => handleEdit(openEditModal.IDEmpresa)}>Editar</Button></div>
                    </div>
                    </div>


            </Modal>
            </div>}


            {openDeleteModal && <div className="container"><Modal
                className="custom-Modal"
                isOpen={true}
                onRequestClose={() => setOpenDeleteModal(null)}
                contentLabel="edit"
            >
                <div

                    className={"container custom-content-modal col-8 d-flex flex-column"}>
                    <div className=" pt-3 modal-header fs-5">
                        <span>{'Eliminar Empresa'}</span>
                        <button className="btn-close-modal" type="button" onClick={() => setOpenDeleteModal(null)}>
                            X
                        </button>
                    </div>
                    <h5 className='text-center mt-5'>¿Estás seguro que deseas eliminar la empresa <b>{openDeleteModal.Empresa}</b>?</h5>
                    <div className='d-flex flex-row p-4 gap-3 justify-content-center'>
                        <Button onClick={() => setOpenDeleteModal(null)}>Cancelar</Button>
                        <Button className='bg-danger text-white' onClick={() => handleDelete(openDeleteModal.IDEmpresa)}>Eliminar</Button></div>
                </div>
            </Modal>
            </div>}

        </div>
    )

}

export default Empresas;
