import React, { useEffect, useState } from 'react'
  import {ModalCrearEmpresa , ModalCrearPlanta, ModalCrearAlcance, ModalCrearMatriz} from './ModalInput';
  import {useForm} from "react-hook-form"
  import { altaEmpresaUser,  altaPlanta , altaAlcance, altaMatriz } from '../../actions/index';
import { useDispatch, useSelector } from 'react-redux';
import { stepWizard } from './js/stepsWizard';
import CustomButtomWizard from './CustomButtonWizard';

const ModalContentInfo = ({onRequestClose ,idEmpresa , iDPlanta}) => {
  const dispatch = useDispatch();
  const[empresa , setEmpresa] = useState ([])
  const[planta , setPlanta] = useState ([])
  const[alcance , setAlcance] = useState ([])
  const[matriz , setMatriz] = useState ([])
  const[funcionSetData , setData] = useState()
  const[funcionEnviar, setFuncionEnviar] = useState()
  const[isLoading, setLoading] = useState(false)
  const wizarStep = useSelector((state) => state.wizarStep);
  const { EMPRESA , PLANTA , ALCANCE , MATRIZ , TEMAS } = stepWizard
  
  const{ register, watch,  formState:{errors, isValid}, handleSubmit }
      = useForm({
    mode:'onChange'
  });



  const [inputName , setInputName] = useState('');

  // cambiar
  useEffect(() => {
    switch (wizarStep) {
      case EMPRESA:
        setInputName('Empresa');
        setFuncionEnviar(() => altaEmpresaUser)
        setData(() => setEmpresa)
        break;
      case PLANTA: 
        setInputName('Planta');
        setFuncionEnviar(() => altaPlanta)
        setData(() => setPlanta)
        setPlanta({
          IDEmpresa:idEmpresa
        })
        break;
      case ALCANCE: 
        setInputName('Alcance');
        setFuncionEnviar(() => altaAlcance)
        setData(() => setAlcance)
        break;
      case MATRIZ:
        setInputName('Matriz');
        setFuncionEnviar(() => altaMatriz)
        setData(() => setMatriz)
        break;
      case TEMAS:
          setInputName('Temas');
        break;

      default:
        break;
    }


  }, [wizarStep]);

  const handleFormSubmit = async (data)  => {

    setLoading(true);
    try{
      switch (inputName) {
        case "Empresa":
          dispatch(funcionEnviar(empresa));
          break;
        case "Planta":
          dispatch(funcionEnviar(planta));
          break;
        case "Alcance":
          dispatch(funcionEnviar({alcance,  iDPlanta}));
          break;
        case "Matriz":
          dispatch(funcionEnviar(matriz));
          break;
        default:
          break;
      }
    } catch (error){

    } finally {
      setLoading(false);
    }}

        const handleChangeFormMatriz = (event, id) => {
          let isChecked = event.target.checked;

          if (isChecked) {
            setMatriz(
                {
                    idTipoMatriz: id,
                    iDPlanta: iDPlanta,
                    FechaInicio : "12-28-2023",
                    FechaCierre : null
                },

            );
          } else {
            setMatriz([])
          }
        }



        const handleChangeFormAlcance = (event) =>{
        event.preventDefault()

        funcionSetData((prevInputName) => ({
        ...prevInputName,
        'IDMunicipio': event.target.selectedOptions[0].getAttribute('data-id'),
        }))
        }

        const handleChangeForm = (event) =>{
        event.preventDefault()

        funcionSetData((prevInputName) => ({
        ...prevInputName,
        [event.target.name]: event.target.value,
        }))
        }





        
  return (
      <div
       className={`${wizarStep=== TEMAS? "m-2 custom-content-modal-temas " : "container custom-content-modal col-8 d-flex flex-column"} `}>
      <div className=" pt-3 modal-header fs-5">
        <span>{inputName}</span>
        <button className="btn-close-modal" type="button" onClick={onRequestClose}>
          X
        </button>
      </div>
      <hr/>
      <form onSubmit={handleSubmit(handleFormSubmit)} className=" modal-body d-flex flex-column align-items-center justify-content-around">
        <div className="col-12 g-4 has-validation">
        {wizarStep === EMPRESA && (
            <ModalCrearEmpresa register={register} errors={errors} inputName={inputName} handleChangeForm={handleChangeForm} />
          )}
        {wizarStep === PLANTA && (
            <ModalCrearPlanta register={register} errors={errors} watch={watch} handleChangeForm={handleChangeForm} />
          )}
        {wizarStep === ALCANCE && (
            <ModalCrearAlcance register={register} errors={errors} watch={watch} handleChangeFormAlcance={handleChangeFormAlcance} />
          )}
        {wizarStep === MATRIZ && (
            <ModalCrearMatriz register={register} errors={errors} watch={watch}  handleChangeFormMatriz={handleChangeFormMatriz}/>
          )}
        </div>
        <div className="modal-footer mb-3">
            <CustomButtomWizard
              title={`${wizarStep !== TEMAS? "Crear" : "Agregar"} ${inputName}`}
              type={'submit'}
              disabled={isLoading}
            />
        </div>
      </form>
      </div>
  )
}

export default ModalContentInfo
