import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {fetchDataNiveles, getDataNivel4, getDataNivel3, getTipoMatrices} from '../../actions'
import useToken from 'antd/es/theme/useToken'
import {RESET_DATA_LEVEL, SET_TIPO_MATRICES} from '../../reducers/constReducer.js'
import '../../main.scss'


const ModalCrearEmpresa = ({register ,errors, inputName , handleChangeForm}) => {
  return (
      <div className="mx-0 mb-3 row w-100 has-validation modal-center">
          <strong className="w-75 mb-2">
                Nombre de la {inputName}
           </strong> 
          <div className="w-75">
             <input {...register('Empresa' ,{required:{
              value:true,
              message: "El campo no puede estar vacío"
             } })}  onChange={handleChangeForm} id={`input${inputName}`} type="text"
              name="Empresa"
              className={`form-control ${errors?  errors.Empresa?.type=== "required"? "is-invalid" : "" : ""} `}/>
               {errors.Empresa?.type === "required" && (
                  <p className="invalid-feedback"> {errors.Empresa.message}</p>
                )}
          </div>
      </div>

    )
  }

const ModalCrearPlanta = ({register, errors , watch ,handleChangeForm}) => {

  // const [inputs] = useState(['Nombre', 'Domicilio'])
  const [inputs] = useState(['Planta', 'Direccion'])

  return (
<div className='container d-flex flex-column'>
  {inputs.map((name) => {

    return (
      <div className='d-flex  justify-content-center col  mb-3 has-validation' key={name}>
        <label htmlFor={`input${name}`} className='form-label col-4 me-2'>
          <strong>{name}</strong>
        </label>
        <div className=''>
            <input
              //value={data.Empresa[`planta${name}`]}
              {...register(`${name}`,
              {required:{
                value:true,
                message: `El campo ${name} no puede estar vacío`
              } })} 
              type="text"
              name={name}
              className={`form-control ${
                errors && errors[`${name}`]?.type === "required" ? "is-invalid" : 
                (watch(`${name}`) && !errors[`${name}`]?.type) ? "is-valid" : ""
              }`}
              id={`input${name}`}
              onChange={handleChangeForm}
              />

            {errors[`${name}`]?.type === "required" && (
              <p className="invalid-feedback">{errors[`${name}`].message}</p>
              ) }
          </div>
      </div>
    );
  })}
</div>
  )
}

const ModalCrearAlcance = ({ register, errors , watch , handleChangeFormAlcance}) => {
  const idPais = useSelector((state)=>state.pais_selected)
  const user = useSelector((state) => state.user);
  const nivelesData = useSelector((state) => state.nivelesData);
  const nivel2Data = useSelector((state) => state.nivel2Data);
  const nivel3Data = useSelector((state) => state.nivel3Data);
  const nivel4Data = useSelector((state) => state.nivel4Data);
  const dispatch = useDispatch();
  const [selects , setSelect] = useState([])
  
  

    useEffect(()=> {
      dispatch(fetchDataNiveles (idPais, user.id_token))
    },[])

    useEffect(()=> {
      if( nivelesData && nivelesData.Niveles !== 4){
        setSelect([nivelesData.Nivel3 , nivelesData.Nivel4])
      } else {
        setSelect([nivelesData.Nivel2 , nivelesData.Nivel3 , nivelesData.Nivel4])
      }
    },[nivelesData])

    const resetDatosSelects = () => {
      dispatch({
        type: RESET_DATA_LEVEL,
        payload: []
      })

    }
    
    const handleChargeInfoSelects = (e ,currentIndex) => {
      const idValueSelect = e.target.selectedOptions[0].getAttribute('data-id');

        if(nivelesData.Niveles !== 4){
           if(currentIndex === 0 ){
            dispatch(getDataNivel4( {idValueSelect, idPais } , user.id_token))
          }
          if(currentIndex == 1) {
            handleChangeFormAlcance(e)
          }
        } else {
          
          if(currentIndex === 0) {
            resetDatosSelects()
            dispatch(getDataNivel3({ idValueSelect , idPais} , user.id_token))
          } else if(currentIndex === 1){
            let nivel = 'nivel4'
            dispatch(getDataNivel4( {idValueSelect, idPais , nivel} , user.id_token))
            
          }
          
        }
        if(currentIndex == 2) {
          handleChangeFormAlcance(e)
        }
      }

    return (
      <>
        {selects?.map((e, i) => {
          let infoSelects = []

            switch (e) {
              case nivelesData.Nivel2:
                infoSelects = nivel2Data 
                break;
                
                case nivelesData.Nivel3:
                infoSelects = nivel3Data
                break;
                
                case nivelesData.Nivel4:
                infoSelects = nivel4Data
                break;
            
              default:
                break;
            }

          return(
            <div className='d-flex justify-content-center  col align-items-center mb-3' key={i}>
               <label htmlFor={`select${e}`} className='col-4 form-label me-2'>
                <strong>{e}</strong>
               </label>
               <select onChange={(e) => handleChargeInfoSelects(e ,i)} name={`alcance${e}`} className='form-control' id={`select${e}`}>
                <option value="">
                  Seleccione {e}
                </option>
                {infoSelects?.map((option, j) => {
                  return (
                    <option value={option.Nombre} data-id={option.Id} key={j}>{option.Nombre}</option>
                  );
                })}
              </select>
            </div>
                  )
                })}

              </>
            )

}
const ModalEditarEmpresa = ({empresa, handleClose}) => {


}


const ModalCrearMatriz = ({ register, errors , watch  ,handleChangeFormMatriz }) => {
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(null);
  const matricesIdplanta = useSelector((state) => state.matricesIdplanta);
  const tipoMatrices = useSelector((state) => state.tipoMatrices);
  const dispatch = useDispatch()
  useEffect(()=>{
      if(!tipoMatrices.length) getTipoMatrices().then(tipos=> dispatch({
        type: SET_TIPO_MATRICES,
        payload: tipos
      }))

      },[])

    return(
      <ul className={'p-0'}>
      {tipoMatrices.map((e, index) => {
        const estaEnMatricesIdPlanta = matricesIdplanta.some(
          (matriz) => matriz.IDTipoMatriz === e.IDTipo
        );
        return (
          <li className={`d-flex`} key={index} style={{ borderBottom: '1px solid #8080804d' }}>
            <label className="p-2">
            <input
              type="checkbox"
              value={e.IDTipo}
              className={'margin-right-15px'}
              name={e.TipoMatriz}
              checked={opcionSeleccionada === e.IDTipo}
              disabled={estaEnMatricesIdPlanta}
              onChange={(event) => {
                if (!estaEnMatricesIdPlanta) {
                  setOpcionSeleccionada(e.IDTipo);
                  handleChangeFormMatriz(event, e.IDTipo);
                }
              }}
            />
                {e.TipoMatriz}

            </label>
          </li>
        );
      })}
    </ul>
    )


}
  


export{
  ModalCrearEmpresa,
  ModalCrearPlanta,
  ModalCrearAlcance,
  ModalCrearMatriz,
    ModalEditarEmpresa,

}
