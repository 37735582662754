export const SET_USER_REDUCER = 'SET_USER_REDUCER'
export const SET_EMPRESAS = 'SET_EMPRESAS'
export const WIZAR_NEXT = "WIZAR_NEXT"
export const WIZARD_PREVIOUS = "WIZARD_PREVIOUS"
export const SET_PLANTS_BY_IDEMP = "SET_PLANTS_BY_IDEMP"
export const CLOSE_MODAL = "CLOSE_MODAL"
export const SET_ALCANCES_BY_IDEMP = "SET_ALCANCES_BY_IDEMP"
export const SET_MATRICES_BY_IDPLANTA = "SET_MATRICES_BY_IDPLANTA"
export const SET_THEMES_MATRIZ_BY_IDPLANTA = "SET_THEMES_MATRIZ_BY_IDPLANTA"
export const SET_THEMES_SELECTED_BY_IDPLANTA = "SET_THEMES_SELECTED_BY_IDPLANTA"
export const REMUVE_THEMES_SELECTED_BY_IDPLANTA = "REMUVE_THEMES_SELECTED_BY_IDPLANTA"
export const FETCH_DATA_NIVELES = "FETCH_DATA_NIVELES"
export const ADD_INFOSELECTS = "ADD_INFOSELECTS"
export const SET_TIPO_MATRICES = "SET_TIPO_MATRICES"
// const NIVEL_2_ENDPOINT = '/api/niveles2';
// const NIVEL_3_ENDPOINT = 'URL_DEL_ENDPOINT_PARA_NIVEL_3';
// const NIVEL_4_ENDPOINT = 'URL_DEL_ENDPOINT_PARA_NIVEL_4';
export const LIMIT_ITEMS = "LIMIT_ITEMS"
export const RESET_DATA_LEVEL = "RESET_DATA_LEVEL"
export const RESET_WIZARD = "RESET_WIZARD"
export const OPEN_MODAL = "OPEN_MODAL"
export const SET_USER_PLAN = "SET_USER_PLAN"
export const SET_PLANTAS = 'SET_PLANTAS';
export const SET_USER_STATUS = 'SET_USER_STATUS';
export const SET_NORMAS_TABLE = 'SET_NORMAS_TABLE'
export const CLEAR_ALCANCES_BY_IDEMP = 'CLEAR_ALCANCES_BY_IDEMP';
export const SET_GROUP_NORMAS_TABLE = 'SET_GROUP_NORMAS_TABLE'
export const SET_OBLIGACIONES_TABLE = 'SET_OBLIGACIONES_TABLE'
export const SET_AUTORIDAD_APLICACION = 'SET_AUTORIDAD_APLICACION'
export const SET_ALCANCES = 'SET_ALCANCES'
export const SET_PAIS_SELECTED = 'SET_PAIS_SELECTED'
export const PAISES_OPTIONS = 'PAISES_OPTIONS'
export const RESET_REQUEST_OBLIGACIONES = 'RESET_REQUEST_OBLIGACIONES';
export const TEMAS_SELECTED = 'TEMAS_SELECTED'

