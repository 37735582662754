import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'; // Importa Link
import Navbar from '../Components/Navbar'
import './inicio.css'; // Importa tu archivo CSS
import '../main.scss'
import { globalJSONContext } from '../context/globalJsonDataContext';
import {useDispatch, useSelector} from 'react-redux';
import {getEmpresasUser, getPlantaByEmpresaID, getUserPlan} from "../actions";
import Dashboard from "../Components/Dashboard";
import CustomButtonWizard from "../Components/modalWizard/CustomButtonWizard";
import {SET_USER_PLAN} from "../reducers/constReducer";

const Inicio = () => {
const {globalJSON} = useContext(globalJSONContext)
const user = useSelector((state) => state.user)
const dispatch = useDispatch()
const empresasUser = useSelector((state) => state.empresasUser);
const plantasEmpresa = useSelector((state) => state.plantasEmpresa);
const matricesIdplanta = useSelector((state) => state.matricesIdplanta);
const userPlan = useSelector((state) => state.user_plan)

const navigate = useNavigate()
const [matrices, setMatrices] = useState()





    useEffect(() => {

        dispatch(getEmpresasUser(user.id_token))
        dispatch(getUserPlan())

    }, [user]);

   

    let  matricesContratadas = userPlan[0]?.MatricesContratadas;
    let matricesCreadas  = userPlan[0]?.MatricesContratadas - userPlan[0]?.MatricesDisponibles 

  return (
    <div>
      <Navbar />
      <div className="bg-container">
        <img src={process.env.PUBLIC_URL + './images/bg-heading-01.png'} alt="Heading" className="bg-image" />
        <div className="container text-container">
          <div className="row">
            <div className="col-9">
              <h2 className="title fw-semibold">¡{globalJSON.greeting?.hello} {user.profile?.name}!</h2>
              <p className="lead text-white mb-0" style={{width:'40%'}}>{globalJSON.greeting?.greetingSubTitle}</p>
            </div>
              <div className='col-3 d-flex flex-column align-items-center'>
                  <p className='fw-bolder text-center' style={{fontSize:'20px'}}>Hay  {isNaN(matricesCreadas) ? '': matricesCreadas} matriz creada <br/> de {matricesContratadas}  disponibles</p>
                  <CustomButtonWizard
                      onClick={() => navigate('/wizard')}
                  title='Crear matriz'
                  />

              </div>
          </div>
        </div>
      </div>
        {!!empresasUser.length ? <Dashboard empresas={empresasUser} plantas={plantasEmpresa} matrices={matricesIdplanta}></Dashboard> :
      <div className="app-card-body bg-white container-pc">
  <div className="container-lg py-4 py-lg-0">
    <div className="row pb-4 pb-md-0">
    <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-4 py-md-0 d-flex flex-column align-items-center justify-content-center">
            <h1 className="title mb-4 mb-lg-5 px-3 px-md-0 text-center fw-semibold fs-1">Aún no hay matrices creadas</h1>
            <Link to="/matrices" className="btn btn-eleg btn-large fw-bold">Comenzar</Link> {/* Usa Link en lugar de <a> */}
          </div>
      <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
        
        <div className="tutorial-intro">
          <div className="tutorial-intro-txt">
          <h3 className="mb-3 mb-lg-4 px-4 px-md-0 fw-500">Mira el video tutorial para crear tu primer matriz online facilmente</h3>
            <a href="#" className="btn btn-eleg btn-large mx-auto fw-bold">Ver Video</a>
          </div>
          <img src={process.env.PUBLIC_URL + './images/desktop-laptop.png'} alt="" className="tutorial-intro-img" />
        </div>
      </div>
    </div>
  </div>
</div>}
    </div>
    
  );
}

export default Inicio;
