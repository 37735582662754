const OidcMetadata = {
    issuer: process.env.REACT_APP_OIDC_ISSUER,
    authorization_endpoint: process.env.REACT_APP_OIDC_AUTHORIZATION,
    userinfo_endpoint: process.env.REACT_APP_OIDC_USERINFO,
    token_endpoint: process.env.REACT_APP_OIDC_TOKEN,
    // check_session_iframe: "", // optional 
    // end_session_endpoint: "", // optional 
    // revocation_endpoint: "", // optional 
    jwks_uri: process.env.REACT_APP_OIDC_JWKS, // optional 
}

const OidSettings = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    client_secret:process.env.REACT_APP_CLIENT_SECRET,
    response_type:process.env.REACT_APP_RESPONSE_TIPE,
    metadata: OidcMetadata,
    metadataUrl: process.env.REACT_APP_METADATAURL,
    silent_redirect_uri:  process.env.REACT_APP_SILENT_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: process.env.REACT_APP_SCOPE,
};

export default OidSettings;