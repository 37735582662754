import React from 'react'

const CustomButtomWizard = ({
    disabled,
    onClick,
    title,
    type,
  }) => {
  return (
    <button
        disabled={disabled}
        type={type}
        className={`btn btn-eleg fw-semibold ${disabled ? 'disabled' : '' }`}
        onClick={onClick}

    >
        {title}
    </button>
  )
}

export default CustomButtomWizard