import arrow from "../images/dashboard-arrow.svg"
import whitearrow from "../images/dashboard-arrow-white.svg"
import {useState, useEffect} from "react";
import {cleanMatrices, getMatrices, getPlantaByEmpresaID} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";


function Dashboard({empresas, plantas, matrices}){

    const navigate = useNavigate()

    const [selectedEmpresa, setSelectedEmpresa] = useState(undefined)
    const [selectedPlanta, setSelectedPlanta] = useState(undefined)
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)

    useEffect(() => {
        dispatch(getPlantaByEmpresaID(user.id_token, selectedEmpresa ))
        setSelectedPlanta(undefined)
        dispatch(cleanMatrices())

    },[selectedEmpresa] )

    useEffect(() => {
        dispatch(getMatrices(selectedPlanta ,user.id_token ))

    }, [selectedPlanta]);


    function handleSelectedPlanta(plantaId) {
        if (selectedPlanta === plantaId) return
        setSelectedPlanta(plantaId)
    }

    function handleSelectedEmpresa(empresaId){

        if(selectedEmpresa === empresaId) return
        setSelectedEmpresa(empresaId)
    }

    return(
        <div className='dashboard'>
            <h2 className='fw-semibold'>
                Mis Matrices
            </h2>
            <h5 className='mb-5'>
                Elija una empresa para ver sus plantas y matrices vinculadas
            </h5>
            <div className='menu-container'>
                <Menu
                    title={'Empresas'}
                    options={empresas}
                    value={selectedEmpresa}
                    onClick={handleSelectedEmpresa}
                    type={'Empresa'}
                />
                <Menu
                    title={'Plantas'}
                    options={plantas}
                    value={selectedPlanta}
                    onClick={handleSelectedPlanta}
                    type={'Planta'}
                />
                <Menu
                    title={'Matrices'}
                    options={matrices}
                    value={'lolo'}
                    onClick={(id) => {navigate(`/normas/${id}`)}}
                    type={'Matriz'}
                />

            </div>
        </div>

    )
}

function Option({title, selected = false, onClick}) {


    return (
        <div className={`option-button-style ${selected ? "selected" : ""}`}
            onClick={onClick}
        >
            <p className='text-style'>{title}</p>
            <img src={selected ? whitearrow : arrow} alt='arrow-image' className={'arrow-image'}/>
            <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="flt_tag">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur"/>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                                       result="flt_tag"/>
                        <feComposite in="SourceGraphic" in2="flt_tag" operator="atop"/>
                    </filter>
                </defs>
            </svg>
        </div>

    )
}

const optionData = (option) => ({
    'Empresa': {
        title: option['Empresa'],
        id: option['IDEmpresa']

    },

    'Planta':{
        title: option['Planta'],
        id: option['IDPlanta']
    },

    'Matriz':{
        title: option['Matriz'],
        id: option['IDMatriz']
    }
})

function Menu({options, value, title, onClick, type}) {

    return(

        <div className='menu-style'>
            <h6 className='text-center fw-semibold sticky-top bg-white'>{title}</h6>

            {options && options.map(option => {

                const {title, id} = optionData(option)[type]

                return <Option
                    key={id}
                    title={title}
                    selected={value === id}
                    onClick={() => onClick(id)}
                ></Option>
            })}
        </div>
    )
}

export default Dashboard