import { SET_EMPRESAS, SET_PLANTAS } from "./constReducer";

export const initialState = {
  user_plan: [],
  user: {},
  empresasUser: [],
  plantasEmpresa: [],
  plantasUser: [], // Añadido para plantas del usuario
  alcancesIdplanta: [],
  matricesIdplanta: [],
  temasIdplanta: [],
  temasSelectedIdPlanta: [],
  wizarStep: 0,
  openModal: false,
  maxAlcances: 0,
  maxMatriz: 0,
  nivelesData: {},
  pais: "",
  limitItems: {
    isLimit: false,
    item: "",
  },
  nivel2Data: [],
  nivel3Data: [],
  nivel4Data: [],
  tipoMatrices: [],
  user_status: [],
  normas_table: {},
  group_normas_table:{},
  autoridad_aplicacion:{},
  pais_selected: 1,
  paises_options:[],
  resetRequestObligaciones: false,
  temas_selected : []
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER_REDUCER":
      return {
        ...state,
        user: action.payload,
        pais: action.payload.profile.country || "1",
        maxAlcances: action.payload.profile.alcances || 2,
        maxMatriz: action.payload.profile.matriz || 2,
      };
    case SET_EMPRESAS:
      return {
        ...state,
        empresasUser: action.payload,
      };
    case SET_PLANTAS:
      return {
        ...state,
        plantasUser: action.payload,
      };
    case "SET_PLANTS_BY_IDEMP":
      return {
        ...state,
        plantasEmpresa: action.payload,
      };
    case "SET_ALCANCES_BY_IDEMP":
      const nuevosAlcances = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const alcancesActualizados = [
        ...state.alcancesIdplanta,
        ...nuevosAlcances.filter(
          (nuevoAlcance) =>
            !state.alcancesIdplanta.some(
              (alcance) =>
                alcance.IDPlantaMunicipio === nuevoAlcance.IDPlantaMunicipio
            )
        ),
      ];

      return {
        ...state,
        alcancesIdplanta: alcancesActualizados,
      };
    case "CLEAR_ALCANCES_BY_IDEMP":
      return {
        ...state,
        alcancesIdplanta: [],
      };
    case "SET_TIPO_MATRICES":
      return {
        ...state,
        tipoMatrices: action.payload,
      };
    case "SET_MATRICES_BY_IDPLANTA":
      const nuevasMatrices = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      const matricesActualizadas = [
        ...state.matricesIdplanta,
        ...nuevasMatrices.filter(
          (nuevaMatriz) =>
            !state.matricesIdplanta.some(
              (matriz) => matriz.IDMatriz === nuevaMatriz.IDMatriz
            )
        ),
      ];

      return {
        ...state,
        matricesIdplanta: matricesActualizadas,
      };
    case "SET_THEMES_MATRIZ_BY_IDPLANTA":
      return {
        ...state,
        temasIdplanta: action.payload,
      };
    case "SET_THEMES_SELECTED_BY_IDPLANTA":
      if (state.temasSelectedIdPlanta.length !== 0) {
        return {
          ...state,
          temasSelectedIdPlanta: [
            ...state.temasSelectedIdPlanta,
            action.payload[0],
          ],
        };
      } else {
        return {
          ...state,
          temasSelectedIdPlanta: action.payload,
        };
      }
    case "REMUVE_THEMES_SELECTED_BY_IDPLANTA":
      const nuevosTemasSelectedIdPlanta = state.temasSelectedIdPlanta.filter(
        (temaId) => !action.payload.includes(temaId)
      );
      return {
        ...state,
        temasSelectedIdPlanta: nuevosTemasSelectedIdPlanta,
      };
    case "LIMIT_ITEMS":
      return {
        ...state,
        limitItems: {
          isLimit: action.payload.length !== state[action.limit] ? false : true,
          item: action.item,
        },
      };
    case "FETCH_DATA_NIVELES":
      return {
        ...state,
        nivelesData: action.payload,
      };
    case "ADD_INFOSELECTS":
      switch (action.select) {
        case "Nivel2":
          return {
            ...state,
            nivel2Data: action.payload,
          };
        case "Nivel3":
          return {
            ...state,
            nivel4Data: [],
            nivel3Data: action.payload,
          };
        case "Nivel4":
          return {
            ...state,
            nivel4Data: action.payload,
          };
        default:
          return state;
      }
    case "RESET_DATA_LEVEL":
      if (action.payload.length === 0) {
        return {
          ...state,
          nivel3Data: [],
          nivel4Data: [],
        };
      } else {
        let select = action.payload[0];
        return {
          ...state,
          [select]: [],
        };
      }
    case "RESET_WIZARD":
      return {
        ...state,
        wizarStep: 0,
        empresasUser: [],
        plantasEmpresa: [],
        alcancesIdplanta: [],
        matricesIdplanta: [],
        temasIdplanta: [],
        temasSelectedIdPlanta: [],
      };
    case "WIZAR_NEXT":
      return {
        ...state,
        wizarStep: state.wizarStep + 1,
      };
    case "WIZARD_PREVIOUS":
      return {
        ...state,
        wizarStep: state.wizarStep - 1,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        openModal: false,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        openModal: true,
      };
    case "SET_USER_PLAN":
      return {
        ...state,
        user_plan: action.payload,
      };

    case "SET_USER_STATUS":
      return {
        ...state,
        user_status: action.payload,
      };
    case "SET_NORMAS_TABLE":
      return {
        ...state,
        normas_table: action.payload,
      };
      case "SET_GROUP_NORMAS_TABLE":
      return {
        ...state,
        group_normas_table: action.payload,
      };
      case "SET_OBLIGACIONES_TABLE":
      return {
        ...state,
        group_normas_table: action.payload,
      };
      case "SET_AUTORIDAD_APLICACION":
      return {
        ...state,
        autoridad_aplicacion: action.payload,
      };
      case "SET_PAIS_SELECTED":
      return {
        ...state,
        pais_selected: action.payload,
      };
      case "PAISES_OPTIONS":
      return {
        ...state,
        paises_options: action.payload,
      };
      case "RESET_REQUEST_OBLIGACIONES":
      return {
        ...state,
        resetRequestObligaciones: action.payload,
      };
      case "TEMAS_SELECTED":
      return {
        ...state,
        temas_selected: action.payload,
      };
      
    default:
      return state;
  }
}

export default rootReducer;
