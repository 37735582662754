import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { altaTemas,getUserPlan } from '../../actions'
import { stepWizard } from './js/stepsWizard'
import CustomButtonWizard from './CustomButtonWizard'
import checkSvg  from '../../images/check2-circle-01.svg'
import { useNavigate } from 'react-router-dom';
import '../../main.scss'
import {
    SET_THEMES_SELECTED_BY_IDPLANTA,
    REMUVE_THEMES_SELECTED_BY_IDPLANTA,
    SET_EMPRESAS,
    SET_PLANTS_BY_IDEMP,
    SET_MATRICES_BY_IDPLANTA,
    RESET_WIZARD,
    WIZAR_NEXT,
    WIZARD_PREVIOUS,
    SET_ALCANCES_BY_IDEMP
} from '../../reducers/constReducer.js'

import ButtonAddItems from './ButtonAddItems.jsx'
//import { getTemasMatrices } from '../../actions'
import {getTemasMatrices,clearAlcancesByIdEmp,getTemasByPlant} from '../../actions';


const ShowDatos = ({arrayDatos,lastStep}) => {

  const dispatch = useDispatch();

  const  navigate = useNavigate()


  const handleSetClick = (index,name,idTipoMatriz) => {

     if(!lastStep.finalStep && name == 'Matriz'){
       dispatch(getTemasMatrices({idPais : 1,idTipoMatriz}))
     }
    
   
    dispatch({
      type : arrayDatos.type ,
      payload : [arrayDatos.datos[index]]
    })
    dispatch({
      type: WIZAR_NEXT,
    })


      lastStep.finalStep && navigate(`/normas/${arrayDatos.datos[0].IDMatriz}`)
  }



  return (
    <div className='d-flex flex-column' >
        <ul className="list-resolved-item">

          {arrayDatos.datos?.map((e,index) => {
           return  e?.Municipio == null && arrayDatos.name === "Alcance" ?  null : (
              <li key={index} className='resolved-item  '>
                  <strong>
                       <div className=' item-list item-name'>
                         {arrayDatos.name === "Alcance" ?
                         <>
                           {e.Municipio + " " +  e.Provincia}
                         </>
                         :
                         <>
                         {arrayDatos.name === "Matriz"&& <img className='me-2' src={process.env.PUBLIC_URL + '/images/logo-wizard-matriz.svg'} alt='checked'/>}
                         
                         {e[arrayDatos.name]}  
                           </>}

                       </div>
                    </strong>
                <div>
                  {arrayDatos.name == 'Matriz' && e.TemasActivos > 0 ? <span className='text-teams-active me-3'>Ya tiene temas creados <img src={process.env.PUBLIC_URL + '/images/logo-temas-creados.svg'}  alt='checked'/></span> : <></>}
                  
                <strong>
                  {!arrayDatos.infoSelect && arrayDatos?.name != 'Alcance'? 
                    <button 
                    type="button" 
                    className={`select-item`}
                    onClick={() => handleSetClick(index,arrayDatos?.name,e?.IDTipoMatriz)}>{ lastStep.finalStep ? 'Ver Matriz' : 'Seleccionar' }
                  </button> :
                  ""
                  
                  }
                </strong>
                </div>

              </li>
                )
          })}
        </ul>
      </div>
    )


}




const MenuWizardStep = ({openModal  , iDPlanta}) => {
  // const [selectedMatricesCount , setSelectedMatricesCount] = useState(0)
  const empresasUser = useSelector((state) => state.empresasUser);
  const plantasEmpresa = useSelector((state) => state.plantasEmpresa);
  const alcancesIDplanta = useSelector((state) => state.alcancesIdplanta);
  const matricesIdplanta = useSelector((state) => state.matricesIdplanta);
  const temasIdplanta = useSelector((state) => state.temasIdplanta);
  const temasSelectedIdPlanta = useSelector((state) => state.temasSelectedIdPlanta);
  const limitItems = useSelector((state) => state.limitItems);
  const wizarStep = useSelector((state) => state.wizarStep);
  
  const  navigate = useNavigate()
  const dispatch = useDispatch();

 

  const {EMPRESA , PLANTA, ALCANCE, MATRIZ , TEMAS , MATRIZ_CREADA } = stepWizard

  const[flagSowDatos , setFlagShowDatos]  = useState({})
  const[buttonAndMensaje , setButtonAndMensaje] = useState({
    title: "",
    mensaje: ""
  })

  const [municipiosDisponibles,setMunicipiosDisponibles] = useState(0)
  const [matricesDisponibles,setMatricesDisponibles] = useState(0)

  const user_plan = useSelector(state => state.user_plan); 

  useEffect(()=>{
    dispatch(getUserPlan())
  },[])

  


  useEffect(() => {
    
    

    switch (wizarStep) {
      case EMPRESA:
        setButtonAndMensaje({
         mensaje:"Crear o editar Empresas",
         title: "Empresa"
        })
           setFlagShowDatos({
            datos: empresasUser,
            name : "Empresa",
            type: SET_EMPRESAS,
            infoSelect: false,
           })
        break;
      case PLANTA:
          setButtonAndMensaje({
           mensaje:"Crear o editar Plantas",
           title: "Planta"
          })
           setFlagShowDatos({
            datos: plantasEmpresa,
            name : "Planta",
            type: SET_PLANTS_BY_IDEMP,
            infoSelect: false,
           })
        break;
      case ALCANCE:
           setButtonAndMensaje({
            mensaje:"Crear o configurar Alcance",
            title: "Alcance"
           })
          setFlagShowDatos({
           datos: alcancesIDplanta,
           name : "Alcance",
           type: SET_ALCANCES_BY_IDEMP,
           infoSelect: false,
          })
        break;
      case MATRIZ:
          let mensaje  = `${matricesIdplanta.length > 0 ? 'Crear o Configurar Matriz' : 'Aun no tiene matrices creadas' }`

           setButtonAndMensaje({
            mensaje: mensaje,
            title: "Matriz"
           })
           setFlagShowDatos({
            datos: matricesIdplanta,
            name : "Matriz",
            type: SET_MATRICES_BY_IDPLANTA,
            infoSelect: false,
          })
          break;
      case TEMAS:
           setButtonAndMensaje({
           mensaje:"Seleccionar temas",
           title: ""
          })
          setFlagShowDatos({
          datos: temasIdplanta,
          name : "Temas",
          type: "",
        })
        break;
      case MATRIZ_CREADA:
        const resumen = [empresasUser,plantasEmpresa,matricesIdplanta]
        setButtonAndMensaje({
          mensaje:"Resumen",
          title: "Nueva"
        })
        setFlagShowDatos({
          datos:  resumen,
          name : "MatrizCreada",
          type: "",
          infoSelect: true,
          })
          break;
          
          default:
            break;
          }
        },[ wizarStep , empresasUser, plantasEmpresa, alcancesIDplanta ,matricesIdplanta,temasIdplanta])
  
        

        const exitWizard =() => {
          dispatch({type: RESET_WIZARD})
          navigate('/')
        }

        const handleOpenModal =() => {
          
          if(wizarStep !== TEMAS) {
            openModal()
          } else {
            dispatch(altaTemas({
              IDPlanta: iDPlanta,
              IDTema:  temasSelectedIdPlanta
            }))
          }
        }

        console.log(iDPlanta)
    
        return(
          <>
            <p>
              {buttonAndMensaje.mensaje?buttonAndMensaje.mensaje : "" }
            </p>
            {wizarStep === MATRIZ_CREADA && <ShowResumen arrayDatos={flagSowDatos}/> }

            {wizarStep !== MATRIZ_CREADA && wizarStep !== TEMAS  && <ShowDatos arrayDatos={flagSowDatos} lastStep={{finalStep:false}}/> }

            {wizarStep === TEMAS && <ShowThemes arrayDatos={flagSowDatos} idPlanta={iDPlanta}/> }
   
            <div className='d-flex justify-content-center gap-3 mt-4'>
              { wizarStep !== MATRIZ_CREADA ? 
              <>
                  {wizarStep !== EMPRESA && <CustomButtonWizard
                      type={'button'}
                      title={'Volver'}
                      onClick={() =>{ 
                        dispatch({type: WIZARD_PREVIOUS})
                        wizarStep == ALCANCE && dispatch(clearAlcancesByIdEmp())
                      }}
                  />}

                  {
                    wizarStep == EMPRESA &&
                    <CustomButtonWizard
                    type={'button'}
                    title={`${wizarStep !== TEMAS ? 'Crear' : 'Guardar'}  ${buttonAndMensaje.title}`}
                    onClick={handleOpenModal}  
                    disabled={false}
                />
                  }
                  { wizarStep == PLANTA  &&
                  <CustomButtonWizard
                  type={'button'}
                  title={`${wizarStep !== TEMAS ? 'Crear' : 'Guardar'}  ${buttonAndMensaje.title}`}
                  onClick={handleOpenModal}  
                  disabled={false}
              />
                }
                {wizarStep == ALCANCE &&
                  <CustomButtonWizard
                  type={'button'}
                  title={`${wizarStep !== TEMAS ? 'Crear' : 'Guardar'}  ${buttonAndMensaje.title}`}
                  onClick={handleOpenModal}
                  isActive={false}
                  disabled={user_plan[0]?.MunicipiosDisponibles == 0}
              />
                }
                { wizarStep == MATRIZ &&
                  <CustomButtonWizard
                  type={'button'}
                  title={`${wizarStep !== TEMAS ? 'Crear' : 'Guardar'}  ${buttonAndMensaje.title}`}
                  onClick={handleOpenModal}
                  isActive={false}
                  disabled={user_plan[0]?.MatricesDisponibles == 0}
              />
                }
                { wizarStep == TEMAS &&

                <CustomButtonWizard
                  type={'button'}
                  title={`${wizarStep !== TEMAS ? 'Crear MATRIZ' : 'Guardar'}  ${buttonAndMensaje.title}`}
                  onClick={handleOpenModal}
                  isActive={false}
                  disabled={user_plan[0]?.MatricesDisponibles == 0}
              />
                }
                { wizarStep == MATRIZ || wizarStep == ALCANCE &&
                  <CustomButtonWizard
                  type={'button'}
                  title={'Continuar'}
                  onClick={() => dispatch({type: WIZAR_NEXT})}
                  />
                }
              </> :
              <>
                <CustomButtonWizard
                    type={'button'}
                    title={`Crear ${buttonAndMensaje.title}`}
                    onClick={() =>dispatch({type : RESET_WIZARD})}
                    isActive={user_plan[0]?.MatricesDisponibles == 0}
                />
                <CustomButtonWizard
                type={'button'}
                title={'Salir'}
                onClick={exitWizard}
                />
              </>
              }
            </div>
            { wizarStep == ALCANCE && user_plan[0]?.MunicipiosDisponibles == 0 ? 
              <ButtonAddItems type={'Alcances'}/>
              :  <></>
            } 
            { wizarStep == MATRIZ && user_plan[0]?.MatricesDisponibles == 0 ? 
              <ButtonAddItems type={'Matrices'}/>
              :  <></>
            } 
         </>
  )
  
}

const ShowResumen = ({ arrayDatos }) => {
  const [datos, setDatos] = useState([]);
  
  useEffect(() => {
    if (arrayDatos.datos && arrayDatos.datos.length > 0 && arrayDatos.name === "MatrizCreada") {
      const transformedData = arrayDatos.datos.map(e => ({
        datos: [e[0]],
        name: e[0].Empresa? "Empresa" : e[0].Planta ? "Planta" : "Matriz",
        type: "",
        infoSelect: e[0].Matriz ? false : true,
      }));
      
      setDatos(transformedData);
    }
  }, [arrayDatos]);
  
  return (
          <div>
            {datos.map((e, i) => (
              <ShowDatos key={i} arrayDatos={e} lastStep={{finalStep:true}}/>
            ))}
          </div>
        );
  };





  const ShowThemes = ({ arrayDatos, idPlanta }) => {
    const temasSelectedIdPlanta = useSelector((state) => state.temasSelectedIdPlanta);
    const temasPreSelected = useSelector((state) => state.temas_selected);
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getTemasByPlant(idPlanta, arrayDatos?.datos[0]?.IDTipoMatriz));
    }, [arrayDatos, idPlanta, dispatch]);
  
    const chargeThemes = (event, id) => {
      let isChecked = event.target.checked;
  
      if (isChecked) {
        dispatch({ type: SET_THEMES_SELECTED_BY_IDPLANTA, payload: [id] });
      } else {
        dispatch({ type: REMUVE_THEMES_SELECTED_BY_IDPLANTA, payload: [id] });
      }
    };
  
    const isTemaPreSelected = (idTema) => {
      return temasPreSelected.some((tema) => tema.IDTema === idTema);
    };
  
    return (
      <ul className='scrollable-list'>
        {arrayDatos.datos?.map((e, i) => {
          const isSelected = temasSelectedIdPlanta?.includes(e.IDTema);
          const isPreSelected = isTemaPreSelected(e.IDTema);
          const isChecked = isSelected || e.Activo || isPreSelected;
  
          return (
            <li className="d-flex" key={i} style={{ borderBottom: '1px solid #DADADA' }}>
              <label className="p-3">
                <input
                  type="checkbox"
                  value={e.IDTema}
                  name={e.Tema}
                  checked={isChecked}
                  onChange={(event) => chargeThemes(event, e.IDTema)}
                  disabled={isPreSelected}
                />
                {e.Tema}
              </label>
            </li>
          );
        })}
      </ul>
    );
  };


export default MenuWizardStep
