// import dotenv from 'dotenv'
import axios from "axios"
import {
    ADD_INFOSELECTS,
    CLOSE_MODAL,
    FETCH_DATA_NIVELES,
    LIMIT_ITEMS,
    RESET_DATA_LEVEL,
    SET_ALCANCES_BY_IDEMP,
    SET_EMPRESAS,
    SET_MATRICES_BY_IDPLANTA, SET_PLANTAS,
    SET_PLANTS_BY_IDEMP,
    SET_THEMES_MATRIZ_BY_IDPLANTA,
    SET_THEMES_SELECTED_BY_IDPLANTA,
    SET_TIPO_MATRICES,
    SET_USER_PLAN,
    SET_USER_REDUCER,
    WIZAR_NEXT,
    SET_USER_STATUS,
    SET_NORMAS_TABLE,
    CLEAR_ALCANCES_BY_IDEMP,
    SET_GROUP_NORMAS_TABLE,
    SET_OBLIGACIONES_TABLE,
    SET_AUTORIDAD_APLICACION,
    SET_ALCANCES,
    SET_PAIS_SELECTED,
    PAISES_OPTIONS,
    RESET_REQUEST_OBLIGACIONES,
    TEMAS_SELECTED
} from "../reducers/constReducer.js"



const apiUrl = process.env.REACT_APP_URL_API
const id_token2 = process.env.ID_TOKEN

const instance = axios.create({
    baseURL: apiUrl
})

instance.interceptors.request.use((config) => {
    return {
        ...config,
        headers: {
            ...config.headers,
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('user'))?.id_token}`
        }
    }
})

export const setUserReducer = (payload) => ({
    type: SET_USER_REDUCER,
    payload
})

export const paisSelected = (idPais)=> {

   

    return async function (dispatch) {
        return dispatch({
            type: SET_PAIS_SELECTED,
            payload: idPais
        })
    }

}


export function getPaises(){

    return async function (dispatch) {
        try {
            const response = await instance.get(`api/pais?PaginaResultados=20`)
            return dispatch({
                type: PAISES_OPTIONS,
                payload: response.data.Data
            })
        } catch (error) {
            console.log(error)
        }
    }

}

export function getEmpresasUser(token) { 
    return async function (dispatch) {
        try {
            const response = await instance.get(`api/empresas?PaginaResultados=999`)
            return dispatch({
                type: SET_EMPRESAS,
                payload: response.data.Data
            })
        } catch (error) {
            console.log(error)
        }
    }
}


export function altaEmpresaUser(payload) {

    return async function (dispatch) {
        try {
            const response = await instance.post(`api/empresas`, payload)

            dispatch({
                type: SET_EMPRESAS,
                payload: [response.data]
            })

            dispatch({
                type: WIZAR_NEXT,
            })

            dispatch({
                type: CLOSE_MODAL
            })


        } catch (error) {
            console.log(error)
        }

    }
}

export function getPlantaByEmpresaID(token, id) {
    return async function (dispatch) {
        try {
            // moqueo la line de abajo para traerme de una empresa que tenga plantas relacionadas (despues borrar)
            //    const response = await axios.get(`${apiUrl}/api/plantas?IDEmpresa=10`, {
            const response = await instance.get(`api/plantas?IDEmpresa=${id}`)
            return dispatch({
                type: SET_PLANTS_BY_IDEMP,
                payload: response.data.Data
            })
        } catch (error) {
            console.log(error)
        }
    }
}

export function getNormasByGroup(idMatriz,pageNumber,pageSize,idPais,idProvincia,idMunicipio,temaDataId,norma,idAlcance,idProvinciaAutoridad,idMunicipioAutoridad,idAutoridadAplicacion,idRegion,deBaja){
    return async function (dispatch) {
        try {
            const response = await instance.get(`/api/matrices/${idMatriz}/normas/grupos?Page=${pageNumber}&PageSize=${pageSize}&Eliminado=${deBaja}&IDPais=${idPais}&IDProvincia=${idProvincia}&IDMunicipio=${idMunicipio}&IDTema=${temaDataId}&NormaBase=${norma}&IDProvinciaAutApp=${idProvinciaAutoridad}&IDMunicipioAutApp=${idMunicipioAutoridad}&IDAutoridadAplicacion=${idAutoridadAplicacion}&IDRegion=${idRegion}IDAlcanceAutApp=${idAlcance}`)
                //Faltan parametros IDRegionAutapp,IDPaisAutApp,
             dispatch({
                type: SET_GROUP_NORMAS_TABLE,
                 payload: response.data
            })

            

            return response.data
           
        } catch (error) {
            console.log(error)
        }
    }
}

export function getNormas( idMatriz,pageNumber,pageSize,idAlcance,idPais,idRegion,IDProvincia,IDMunicipio,idTema,norma,idProvinciaAutoridad,idMunicipioAutoridad,idAutoridadAplicacion,deBaja) {

    return async function (dispatch) {
        try {
            const response = await instance.get(`/api/matrices/${idMatriz}/normas?Page=${pageNumber}&PageSize=${pageSize}&Eliminado=${deBaja}&IDAlcance=${idAlcance}&IDPais=${idPais}&IDRegion=${idRegion}&IDProvincia=${IDProvincia}&IDMunicipio=${IDMunicipio}&IDTema=${idTema}&NormaBase=${norma}&IDProvinciaAutApp=${idProvinciaAutoridad}&IDMunicipioAutApp=${idMunicipioAutoridad}&IDAutoridadAplicacion=${idAutoridadAplicacion}`)
            
             dispatch({
                type: SET_NORMAS_TABLE,
                 payload: response.data
            })

            

            return response.data
           
        } catch (error) {
            console.log(error)
        }
    }
}



export function deleteNorma(idNormaMatriz) {
    return async function (dispatch,getState) {
        try {
            

            const response = await instance.delete(`api/norma-matriz/${idNormaMatriz}`);

            if (response){
              
            const currentResetState = getState().resetRequestObligaciones;

            
            dispatch({
                type: RESET_REQUEST_OBLIGACIONES,
                payload: !currentResetState,
            });

            }
            

            return response.data;
        } catch (error) {
            console.error("Error al eliminas la obligación:", error);
        }
    }
}




export function getObligacionesByNorma(idNormaMatriz,page,pageSize){
    return async function (dispatch) {
        try {
            const response = await instance.get(`/api/norma-matriz/${idNormaMatriz}/obligaciones?PageSize=${pageSize}&Page=${page}`)
         
             dispatch({
                type: SET_OBLIGACIONES_TABLE,
                 payload: response.data
            })

            

            return response.data
           
        } catch (error) {
            console.log(error)
        }
    }
}

export function updateObligacion(idNormaMatriz, idObligacion,verificaciones, observaciones,obligacion, archivo) {
    return async function (dispatch,getState) {
        try {
            const payload = {
                Obligaciones:obligacion,
                Verificaciones: verificaciones,
                Observaciones: observaciones,
            };

            const response = await instance.patch(`api/norma-matriz/${idNormaMatriz}/obligaciones/${idObligacion}`, payload);

            if (response){
              
            const currentResetState = getState().resetRequestObligaciones;

            
            dispatch({
                type: RESET_REQUEST_OBLIGACIONES,
                payload: !currentResetState,
            });

            }
            

            return response.data;
        } catch (error) {
            console.error("Error al actualizar la obligación:", error);
        }
    }
}


export function deleteObligacion(idNormaMatriz, idObligacion) {
    return async function (dispatch,getState) {
        try {
            

            const response = await instance.delete(`api/norma-matriz/${idNormaMatriz}/obligaciones/${idObligacion}`);

            if (response){
              
            const currentResetState = getState().resetRequestObligaciones;

            
            dispatch({
                type: RESET_REQUEST_OBLIGACIONES,
                payload: !currentResetState,
            });

            }
            

            return response.data;
        } catch (error) {
            console.error("Error al eliminas la obligación:", error);
        }
    }
}


export function getAutoridadDeAplicacion(idAlcance,idPais,idRegion,idProvincia,idMunicipio){
    
    return async function (dispatch) {
        try {
            const response = await instance.get(`/api/autoridad-aplicacion?IDAlcance=${idAlcance}&IDPais=${idPais}&IDRegion=${idRegion}&IDMunicipio=${idMunicipio}&IDProvincia=${idProvincia}`)
         
             dispatch({
                type: SET_AUTORIDAD_APLICACION,
                 payload: response.data
            })

            

            return response.data
           
        } catch (error) {
            console.log(error)
        }
    }
}


export function importNormas (idMatriz){
    
    return async function (dispatch) {
        try {
            const response = await instance.post(`/api/matrices/${idMatriz}/normas`,{})

           
            //  dispatch({
            //     type: SET_NORMAS_TABLE,
            //      payload: response.data
            // })

            if(response.data== 'OK'){
                //console.log(response.data)
                //dispatch( getNormasByGroup(idMatriz,1,10))
            }else{
                //TODO  MANEJAR CASO CONTRARIO CON ALERTA EN EL COMPONENTE GRACIAS DE NADA
            }
            
            return response?.data;
           
        } catch (error) {
            console.log(error)
        }
    }
}





export function altaPlanta(payload) {
    return async function (dispatch) {
        try {
            const response = await instance.post(`api/plantas`, payload)


            dispatch({
                type: SET_PLANTS_BY_IDEMP,
                payload: [response.data]
            })

            dispatch({
                type: WIZAR_NEXT,
            })

            dispatch({
                type: CLOSE_MODAL
            })


        } catch (error) {
            console.log(error)
        }

    }
}


// falta definir en el back algo parecido a esto que me traiga todos los alcances definidos el usuario para ese tipo de empresa o planta


export function getAlcances (idPais){

    return async function(dispatch){

        try{
            const response = await instance.get(`/api/alcances?IDPais=${idPais}`,)
           
            dispatch({
                type : SET_ALCANCES,
                payload : response.data
             })

            return response.data

        }catch(error){
            console.log(error)
        }

    }
}



export function getAlcancesByEmpresaID(payload,userStatus) {
    

    return async function(dispatch){

        try{
            const response = await instance.get(`${apiUrl}/api/plantas/${payload}/alcances?IDUsuarioPais=${userStatus}`,)


           
            dispatch({
                type : SET_ALCANCES_BY_IDEMP,
                payload : response.data.Alcances
             })
            dispatch({
                type : LIMIT_ITEMS,
                payload : response.data.Alcances,
                item : "alcances",
                limit : "maxAlcances"
                })
            dispatch({
            type : CLOSE_MODAL
            })

        }catch(error){
            console.log(error)
        }

    }
} 


export function altaAlcance(payload) {
    const {alcance, iDPlanta} = payload


    return async function (dispatch) {
        try {

            const response = await instance.post(`api/plantas/${iDPlanta}/alcance`, {... alcance, IDPais: '1'})


            dispatch({
                type: SET_ALCANCES_BY_IDEMP,
                payload :  [response.data ]

            })


            dispatch({
                type: CLOSE_MODAL
            })


        } catch (error) {
            console.log(error)
        }

    }
}

export function fetchDataNiveles(idPais, token) {
    return async function (dispatch) {
        try {
            const response = await instance.get(`api/pais/${idPais}`)


            dispatch({
                type: FETCH_DATA_NIVELES,
                payload: response.data.Data
            })

            if (response.data.Data && response.data.Data.Niveles !== 4) {
                getDataNivel2(idPais, token, dispatch, 'Nivel3')
            } else {
                getDataNivel2(idPais, token, dispatch, 'Nivel2')
            }

        } catch (error) {
            console.log(error)
        }

    }

}

async function getDataNivel2(idPais, token, dispatch, nivel) {

    try {
        const response = await instance.get(`api/niveles2?idPais=${idPais}`);

        const nivel2Data = response.data;


        dispatch({
            type: ADD_INFOSELECTS,
            payload: nivel2Data,
            select: nivel
        });

    } catch (error) {
        console.error('Error al obtener datos de provincias:', error);
    }
}


export function getDataNivel3(payload, token) {
    return async function (dispatch) {
        try {
            const {idValueSelect, idPais} = payload
            const response = await instance.get(`api/niveles2/${idValueSelect}/niveles3?idPais=${idPais}`)

            const nivel3Data = response.data;

            dispatch({
                type: ADD_INFOSELECTS,
                payload: nivel3Data,
                select: 'Nivel3'
            });

        } catch (error) {
            console.log(error)

        }

    }
}


export function getDataNivel4(payload, token) {
    const {idPais, idValueSelect, nivel} = payload


    return async function (dispatch) {
        try {
            let response;

            if (nivel) {
                response = await instance.get(`api/niveles3/${idValueSelect}/niveles4?`,
                    {

                        params: {idPais: idPais}
                    })

            } else {
                response = await instance.get(`api/niveles2/${idValueSelect}/niveles3?`,
                    {

                        params: {idPais: idPais}
                    })
            }

            const nivel4Data = response.data;

            dispatch({
                type: ADD_INFOSELECTS,
                payload: nivel4Data,
                select: "Nivel4"
            });


        } catch (error) {
            console.log(error)

            dispatch({
                type: RESET_DATA_LEVEL,
                payload: ['nivel4Data']
            })

        }
    }
}




export async function getTipoMatrices(payload) {
    try {
        // const response = await axios.get(`${apiUrl}/api/tipo-matrices`, {
        const response = await instance.get(`api/tipo-matrices`);

        // dispatch({
        //     type : SET_TIPO_MATRICES,
        //     payload : response.data.Data
        // })

        return response.data.Data

    } catch (error) {
        console.log(error)
    }
}

export function getMatriz(id){
    return async function (dispatch){
        try{
            const response = await instance.get(`api/matrices/${id}`)

            console.log(response)

            return response
        }catch (error){
            console.log(error)
        }
    }
}

export function getMatrices(id) {
    return async function (dispatch) {
        try {
           

            const tipoMatricesResponse = await getTipoMatrices(id)


            const matricesResponse = await instance.get(`api/matrices?IDPlanta=${id}`);
            

            const tipoMatrices = tipoMatricesResponse;
            const matrices = matricesResponse.data.Data;

            const tipoMatricesMap = tipoMatrices.reduce((map, tipo) => {
                map[tipo.IDTipo] = tipo.TipoMatriz;
                return map;
            }, {});

            const matricesWithNames = matrices.map(matriz => {
                const tipoMatriz = tipoMatricesMap[matriz.IDTipoMatriz];
                return {
                    ...matriz,
                    Matriz: tipoMatriz ? tipoMatriz : 'Tipo de matriz desconocido'
                };
            });
          
            dispatch({
                type: SET_TIPO_MATRICES,
                payload: tipoMatricesResponse
            })


            dispatch({
                type: SET_MATRICES_BY_IDPLANTA,
                payload: matricesWithNames
            })

            dispatch({
                type: LIMIT_ITEMS,
                payload: matrices,
                item: "matrices",
                limit: "maxMatrices"
            });
        } catch (error) {

            console.log(error);
        }
    };
}

export function cleanMatrices() {
    return async function (dispatch) {
        dispatch({
            type: SET_MATRICES_BY_IDPLANTA,
            payload: []
        })
    }

}

export function altaMatriz(payload) {

    return async function (dispatch) {
        try {

            

            const tipoMatricesResponse = await getTipoMatrices();

            const response = await instance.post(`api/matrices`, {...payload, IDPais: '1'});
            
            const tipoMatrices = tipoMatricesResponse;
            
            const matriz = response.data;
            
            const tipoMatricesMap = tipoMatrices.reduce((map, tipo) => {
                map[tipo.IDTipo] = tipo.TipoMatriz;
                return map;
            }, {});
            
         
            const matrizWithName = {
                ...matriz,
                Matriz: tipoMatricesMap[matriz.IDTipoMatriz] ? tipoMatricesMap[matriz.IDTipoMatriz] : 'Tipo de matriz desconocido'
            };
            
            

            dispatch({
                type: SET_MATRICES_BY_IDPLANTA,
                payload: matrizWithName
            });

            // dispatch({
            //     type: WIZAR_NEXT,
            // });

            dispatch({
                type: CLOSE_MODAL
            });

        } catch (error) {
            dispatch(getMatrices(payload.iDPlanta));
            dispatch({
                type: CLOSE_MODAL
            });
            console.log(error);
        }
    };
}

export function getTemasMatrices(payload) {
    const {idTipoMatriz, idPais} = payload
  
    return async function (dispatch) {
        try {                                                    // usar este cuando se arregle el tema de los IDS Hash
            // const response = await axios.get(`${apiUrl}/api/planta-temas?IDPlanta=${idPlanta}&IDTipoMatriz=${idTipoMatriz}`,{
            const response = await instance.get(`api/temas?IDPais=${idPais}&PaginaResultados=999&Pagina=1&IDTipoMatriz=${idTipoMatriz}`)


            dispatch({
                type: SET_THEMES_MATRIZ_BY_IDPLANTA,
                //    payload : [response.data]
                payload: response.data.Data
                // payload : [
                //     {id: 1,
                //     nombre:"Tema 1"},
                //     {id: 2,
                //     nombre:"Tema 2"},
                //     {id: 3,
                //     nombre:"Tema 3"},
                //     {id: 4,
                //     nombre:"Tema 4"},
                //     {id: 5,
                //     nombre:"Tema 5"},
                //     {id: 6,
                //     nombre:"Tema 6"},
                // ]
            })

            return response.data.Data
            //    dispatch({
            //             type : WIZAR_NEXT,
            //    })

            //     dispatch({
            //     type : CLOSE_MODAL
            //     })

        } catch (error) {
            console.log(error)
        }

    }
}

export function altaTemas(payload) {

    return async function (dispatch) {
        try {
            const response = await instance.post(`api/planta-temas`, payload)


            dispatch({
                type: SET_THEMES_SELECTED_BY_IDPLANTA,
                //    payload : [response.data]
                payload: [1, 2, 3]
            })
            dispatch({
                type: WIZAR_NEXT,
            })

            dispatch({
                type: CLOSE_MODAL
            })

        } catch (error) {
            console.log(error)
        }

    }

}


export function getTemasByPlant(idPlanta,idTipoMatriz) {

    return async function (dispatch) {
        try {
            const response = await instance.get(`api/planta-temas?IDPlanta=${idPlanta}&PaginaResultados=99&Pagina=1&IDTipoMatriz=${idTipoMatriz}`)

            
            dispatch({
                type: TEMAS_SELECTED,
                 payload: response.data.Data
            })

            return response.data.Data

        } catch (error) {
            console.log(error)
        }

    }

}




export const getUserStatus =  (email) => {

    return async function(dispatch){
        try{
            const response = await instance.post(
               `api/user-status`,
               {Email: email})
               return dispatch ({
                   type: SET_USER_STATUS,
                   payload: response.data
               })
           }catch (error){
               console.log(error)
           }
    }
        
}

// export const getUserStatus =  async (email) => {

//     return await instance.post(
//         `api/user-status`,
//         {Email: email}
//     )
// }

export const getUserPlan =  () => {

    return async function (dispatch) {
        try {
            const response =  await instance.post(
            `api/user-plan`)
            return dispatch({
                type: SET_USER_PLAN,
                payload: response.data.Services
            })
        } catch (error) {
            console.log(error)
        }
    }
}



export const deleteEmpresaById = async (idEmpresa) =>{

    return await instance.delete(
        `api/empresas/${idEmpresa}`
    )

}

export const editEmpresaById = async (idEmpresa, newName) => {
    return await instance.patch(
        `api/empresas/${idEmpresa}`,
        {
            Empresa: newName
        }
    )
}


export const deletePlantaById = async (idPlanta) => {
    return await instance.delete(
        `api/plantas/${idPlanta}`
    );
}

export const editPlantaById = async (idPlanta, updatedData) => {
    return await instance.patch(
        `api/plantas/${idPlanta}`,
        updatedData
    );
}

export function getPlantasUser(token) {
    return async function (dispatch) {
        try {
            const response = await instance.get(`api/plantas?PaginaResultados=999`);
            return dispatch({
                type: SET_PLANTAS,
                payload: response.data.Data
            });
        } catch (error) {
            console.log(error);
        }
    }
}


export const clearAlcancesByIdEmp = () => ({
    type: CLEAR_ALCANCES_BY_IDEMP
});

