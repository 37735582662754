import React from 'react'
import Modal from 'react-modal';
import '../../css/modal-template-wizard.css'
import ModalContentInfo from './ModalContentInfo';
import { useSelector } from 'react-redux';

 const ModalContent = ({modalIsOpen, closeModal , idEmpresa ,iDPlanta}) => {
  const wizarStep = useSelector((state) => state.wizarStep);
  
  return (
    <div className="container">
      { wizarStep !== 4 &&
        <Modal className={`${wizarStep  !== 4 && 'custom-Modal'}`}
        isOpen={modalIsOpen}
        onRequestClose={() => closeModal()}
        contentLabel=""
        >
        <ModalContentInfo  onRequestClose={() => closeModal()}  idEmpresa={idEmpresa} iDPlanta={iDPlanta}/>
      </Modal>
        }
    </div>
  )
}

export default ModalContent